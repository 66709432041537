import axios from 'axios'

export default {
    catalog(payload) {
        let id = payload.id;
        delete payload.id;
        return axios.get('v1/catalog' + (id === undefined ? "" : "/" + id), payload)
    },
    catalogSearch(payload) {
        return axios.get('v1/catalog/search', payload)
    },

}
