<template>
  <div id="app">
    <Header
            :onAddressModalOpen="addressModalOpen"/>

    <router-view/>

    <AddressModal
            v-if="isAddressModalOpen"
            :isAddressModalOpen="isAddressModalOpen"
            :isAddressModalToCheckout="isAddressModalToCheckout"
            :onAddressModalClose="addressModalClose"/>

    <AuthModal
            ref="authModal"/>

    <CookiesWindow
            v-if="!isCookiesWindowAccepted()"/>

      <div v-if="this.$store.state.loading.showScreenLoading" class="screen-loading">
          <span class="screen-loading__svg"><span><span></span></span></span>
      </div>
  </div>
</template>

<script>
    import Header from './components/Header.vue'
    import AddressModal from "./components/AddressModal.vue"
    import AuthModal from "./components/AuthModal.vue"
    import CookiesWindow from './components/CookiesWindow.vue'

    export default {
        name: 'App',
        data() {
            return {
                isAddressModalOpen: false,
                isAddressModalToCheckout: false
            }
        },
        components: {
            Header,
            AddressModal,
            AuthModal,
            CookiesWindow,
        },
        methods: {
            addressModalOpen: function () {
                this.isAddressModalOpen = true;
            },
            addressModalClose: function () {
                this.isAddressModalOpen = false;
            },
            isCookiesWindowAccepted() {
                return !!localStorage.getItem('cookiesAccept') || null;
            }
        }
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;1,400&display=swap');

    @-webkit-keyframes
    rotating{from{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(360deg)}}
    @keyframes
    rotating{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

    .screen-loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        background: rgba(255, 255, 255, .5);
        -webkit-transition: opacity .2s ease-in, visibility .2s ease-in;
        transition: opacity .2s ease-in, visibility .2s ease-in;
        z-index: 99999999999;

        &__svg {

            >span {
                display: block;
                height: 80px;
                width: 80px;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -40px;
                margin-top: -40px;
                background: #fff;
                box-shadow: 0 4px 11px 0 rgb(0 0 0 / 10%);
                border-radius: 50%;

                >span {
                    display: block;
                    height: 80px;
                    width: 80px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('assets/images/spinner.svg') no-repeat center;
                    -webkit-animation: rotating 2s linear infinite;
                    animation: rotating 2s linear infinite;
                    border-radius: 50%;
                }
            }
        }
    }
</style>