import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/geocode'
import { buildSuccess, handleError } from '@/utils/utils.js'
import { store } from '@/store'

const getters = {
    address: (state) => state.address,
    predictions: (state) => state.predictions,
    addressBySearch: (state) => state.addressBySearch
};

const actions = {
    geocodeByCoordinates({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .geocodeByCoordinates(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_GEOCODE_ADDRESS, response.data);
                    }
                })
                .catch((error) => {
                    commit(types.FILL_GEOCODE_ADDRESS, null);
                    handleError(error, commit, reject);
                })
        })
    },
    geocodeBySearch({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .geocodeBySearch(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_GEOCODE_SEARCH, response.data.result);
                    }
                })
                .catch((error) => {
                    commit(types.FILL_GEOCODE_SEARCH, null);
                    handleError(error, commit, reject);
                })
        })
    },
    geocodeByPlaceId({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .geocodeByPlaceId(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        commit(types.FILL_GEOCODE_ADDRESS, response.data);
                        commit(types.FILL_GEOCODE_ADDRESS_BY_SEARCH, response.data);
                    }
                })
                .catch((error) => {
                    commit(types.FILL_GEOCODE_ADDRESS, null);
                    handleError(error, commit, reject);
                })
        })
    },
};

const mutations = {
    [types.FILL_GEOCODE_ADDRESS](state, data) {
        state.address = data;
    },
    [types.FILL_GEOCODE_SEARCH](state, data) {
        state.predictions = data;
    },
    [types.FILL_GEOCODE_ADDRESS_BY_SEARCH](state, data) {
        state.addressBySearch = data;
    }
};

const state = {
    address: null,
    predictions: [],
    addressBySearch: null
};

export default {
    state,
    getters,
    actions,
    mutations
}