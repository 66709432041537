import axios from 'axios'
import qs from 'qs';

export default {
    orders(payload) {
        return axios.get('v1/orders', payload)
    },
    order(payload) {
        return axios.get('v1/orders/' + payload.id, payload)
    },
    orderCancel(payload) {
        return axios.post('v1/orders/' + payload.id + '/cancel', qs.stringify(payload))
    }
}
