import axios from 'axios'
import qs from 'qs';

export default {
    addressesCreate(payload) {
        return axios.post('v1/addresses', qs.stringify(payload))
    },
    addressesUpdate(payload) {
        return axios.post('v1/addresses/' + payload.id, qs.stringify(payload))
    },
}
