<template>
    <b-modal
             v-model="isOpen"
             hide-footer
             :title="modals[modalActive].title"
             modal-class="login-modal"
             @hidden="close">
        <form v-if="modalActive === 'login'" @submit.prevent="submitSendCode()" action="" method="post">
            <div v-on:click="countryListActive = !countryListActive" class="selected-flag" :class="{ 'selected-flag--active': countryListActive }" tabindex="0" role="button" aria-haspopup="listbox" aria-expanded="true">
                <div class="selected-flag__emoji">
                    <span v-html="phoneCodes[phoneCodePosition].e"></span>
                    <span class="material-icons">expand_more</span>
                </div>
                <span class="selected-flag__code">{{ phoneCodes[phoneCodePosition].c }}</span>
            </div>

            <ul class="country-list" :class="{ 'country-list--active': countryListActive }" role="listbox">
                <li v-on:click="phoneCodePosition = index; countryListActive = false" class="flag" v-for="(item, index) in phoneCodes">
                    <div class="flag__emoji"><span v-html="item.e"></span></div>
                    <span class="flag__name"><span v-html="item.n"></span></span>
                    <span class="flag__code">{{ item.c }}</span>
                    <div class="clearfix"></div>
                </li>
            </ul>

            <div class="input-wrapper input-wrapper--width125 float-left">
                <input v-model="phone"
                       v-mask="phoneCodes[phoneCodePosition].m.length === 0 ? '####################' : phoneCodes[phoneCodePosition].m.replace(phoneCodes[phoneCodePosition].c, '').trim()"
                       id="loginPhone"
                       type="text"
                       class="input-wrapper__input"
                       :class="{ 'input-wrapper__input--error': errorSendCode }"
                       placeholder=" "
                       autocomplete="off"/>
                <label class="input-wrapper__label">Мобилен номер</label>
                <div v-if="errorSendCode" class="input-wrapper__error-icon">
                    <span class="material-icons">error_outline</span>
                </div>
            </div>

            <div class="clearfix"></div>

            <div class="input-wrapper__error-text">{{ errorSendCodeText }}</div>

            <div v-if="this.$store.state.error.showErrorMessage" class="alert alert-danger" role="alert">
                {{ this.$store.state.error.errorMessage }}
            </div>

            <input type="submit" class="login-modal__btn btn btn-lg btn-tilda" :value="'Продължи'"/>
        </form>
        <form v-else @submit.prevent="submitCheckCode()" action="" method="post">
            <p>Моля, въведете кода, изпратен ви на {{ this.phoneCodes[this.phoneCodePosition].c + " " + this.phone }}</p>
            <div class="input-wrapper">
                <input v-model="code" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off" required/>
                <label class="input-wrapper__label">Код от СМС</label>
            </div>

            <template v-if="!sendCodeData.exist">
                <div class="input-wrapper">
                    <input v-model="name" type="text" class="input-wrapper__input" placeholder=" " autocomplete="off" required/>
                    <label class="input-wrapper__label">Име</label>
                </div>

                <div class="input-wrapper">
                    <input v-model="email" type="email" class="input-wrapper__input" placeholder=" " autocomplete="off" required/>
                    <label class="input-wrapper__label">Email</label>
                </div>

                <div class="input-wrapper input-wrapper--height-auto">
                    <b-form-checkbox
                            class="input-wrapper__checkbox"
                            v-model="newsAgree"
                            switch>Искам да бъда информиран за специални<br>промоции и отстъпки</b-form-checkbox>
                </div>
            </template>

            <div v-if="this.$store.state.error.showErrorMessage" class="alert alert-danger" role="alert">
                {{ this.$store.state.error.errorMessage }}
            </div>

            <input type="submit" class="login-modal__btn btn btn-lg btn-tilda" :value="!sendCodeData.exist ? 'Регистрация' : 'Вход'"/>
            <input type="button" @click="clearModal()" class="login-modal__btn btn btn-lg btn-light" value="Назад"/>
        </form>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'AuthModal',
        data: function () {
            return {
                isOpen: false,
                toCheckout: false,

                phone: "",
                code: "",
                name: "",
                email: "",
                newsAgree: true,

                errorSendCode: false,
                errorSendCodeText: "",

                countryListActive: false,

                phoneCodePosition: 32,
                phoneCodes: [{n:"Afghanistan",c:"+93",e:"&#x1F1E6;&#x1F1EB;",m:"+93 ## ### ####"},{n:"Åland Islands",c:"+358",e:"&#x1F1E6;&#x1F1FD;",m:""},{n:"Albania",c:"+355",e:"&#x1F1E6;&#x1F1F1;",m:"+355 ### ### ###"},{n:"Algeria",c:"+213",e:"&#x1F1E9;&#x1F1FF;",m:"+213 ## ### ####"},{n:"American Samoa",c:"+1684",e:"&#x1F1E6;&#x1F1F8;",m:"+1684 ### ####"},{n:"Andorra",c:"+376",e:"&#x1F1E6;&#x1F1E9;",m:"+376 ### ###"},{n:"Angola",c:"+244",e:"&#x1F1E6;&#x1F1F4;",m:"+244 ### ### ###"},{n:"Anguilla",c:"+1264",e:"&#x1F1E6;&#x1F1EE;",m:"+1264 ### ####"},{n:"Antigua & Barbuda",c:"+1268",e:"&#x1F1E6;&#x1F1EC;",m:"+1268 ### ####"},{n:"Argentina",c:"+54",e:"&#x1F1E6;&#x1F1F7;",m:"+54 ### ### ####"},{n:"Armenia",c:"+374",e:"&#x1F1E6;&#x1F1F2;",m:"+374 ## ### ###"},{n:"Aruba",c:"+297",e:"&#x1F1E6;&#x1F1FC;",m:"+297 ### ####"},{n:"Australia",c:"+61",e:"&#x1F1E6;&#x1F1FA;",m:"+61 # #### ####"},{n:"Austria",c:"+43",e:"&#x1F1E6;&#x1F1F9;",m:"+43 ### ### ####"},{n:"Azerbaijan",c:"+994",e:"&#x1F1E6;&#x1F1FF;",m:"+994 ## ### ## ##"},{n:"Bahamas",c:"+1242",e:"&#x1F1E7;&#x1F1F8;",m:"+1242 ### ####"},
                    {n:"Bahrain",c:"+973",e:"&#x1F1E7;&#x1F1ED;",m:"+973 #### ####"},{n:"Bangladesh",c:"+880",e:"&#x1F1E7;&#x1F1E9;",m:"+880 ## ### ###"},{n:"Barbados",c:"+1246",e:"&#x1F1E7;&#x1F1E7;",m:"+1246 ### ####"},{n:"Belarus",c:"+375",e:"&#x1F1E7;&#x1F1FE;",m:"+375 ## ### ## ##"},{n:"Belgium",c:"+32",e:"&#x1F1E7;&#x1F1EA;",m:"+32 ### ### ###"},{n:"Belize",c:"+501",e:"&#x1F1E7;&#x1F1FF;",m:"+501 ### ####"},{n:"Benin",c:"+229",e:"&#x1F1E7;&#x1F1EF;",m:"+229 ## ## ####"},{n:"Bermuda",c:"+1441",e:"&#x1F1E7;&#x1F1F2;",m:"+1441 ### ####"},{n:"Bhutan",c:"+975",e:"&#x1F1E7;&#x1F1F9;",m:"+975 17 ### ###"},{n:"Bolivia",c:"+591",e:"&#x1F1E7;&#x1F1F4;",m:"+591 # ### ####"},{n:"Bosnia & Herzegovina",c:"+387",e:"&#x1F1E7;&#x1F1E6;",m:""},{n:"Botswana",c:"+267",e:"&#x1F1E7;&#x1F1FC;",m:"+267 ## ### ###"},{n:"Brazil",c:"+55",e:"&#x1F1E7;&#x1F1F7;",m:"+55 ## #### ####"},{n:"British Indian Ocean Territory",c:"+246",e:"&#x1F1EE;&#x1F1F4;",m:""},{n:"British Virgin Islands",c:"+1284",e:"&#x1F1FB;&#x1F1EC;",m:"+1284 ### ####"},{n:"Brunei",c:"+673",e:"&#x1F1E7;&#x1F1F3;",m:""},{n:"Bulgaria",c:"+359",e:"&#x1F1E7;&#x1F1EC;",m:"+359 ### ### ###"},
                    {n:"Burkina Faso",c:"+226",e:"&#x1F1E7;&#x1F1EB;",m:"+226 ## ## ####"},{n:"Burundi",c:"+257",e:"&#x1F1E7;&#x1F1EE;",m:"+257 ## ## ####"},{n:"Cambodia",c:"+855",e:"&#x1F1F0;&#x1F1ED;",m:"+855 ## ### ###"},{n:"Cameroon",c:"+237",e:"&#x1F1E8;&#x1F1F2;",m:"+237 #### ####"},{n:"Canada",c:"+1",e:"&#x1F1E8;&#x1F1E6;",m:""},{n:"Cape Verde",c:"+238",e:"&#x1F1E8;&#x1F1FB;",m:"+238 ### ## ##"},{n:"Carribbean Netherlands",c:"+599",e:"&#x1F1F3;&#x1F1F1;",m:""},{n:"Cayman Islands",c:"+1345",e:"&#x1F1F0;&#x1F1FE;",m:"+1345 ### ####"},{n:"Central African Republic",c:"+236",e:"&#x1F1E8;&#x1F1EB;",m:"+236 ## ## ####"},{n:"Chad",c:"+235",e:"&#x1F1F9;&#x1F1E9;",m:"+235 ## ## ## ##"},{n:"Chile",c:"+56",e:"&#x1F1E8;&#x1F1F1;",m:"+56 # #### ####"},{n:"China",c:"+86",e:"&#x1F1E8;&#x1F1F3;",m:"+86 ### #### ####"},{n:"Christmas Islands",c:"+61",e:"&#x1F1E8;&#x1F1FD;",m:""},{n:"Cocos Islands",c:"+61",e:"&#x1F1E8;&#x1F1E8;",m:""},{n:"Colombia",c:"+57",e:"&#x1F1E8;&#x1F1F4;",m:"+57 ### ### ####"},{n:"Comoros",c:"+269",e:"&#x1F1F0;&#x1F1F2;",m:"+269 ## #####"},{n:"Congo-Kinshasa",c:"+243",e:"&#x1F1E8;&#x1F1E9;",m:""},{n:"Congo-Brazzaville",c:"+242",e:"&#x1F1E8;&#x1F1EC;",m:""},
                    {n:"Cook Islands",c:"+682",e:"&#x1F1E8;&#x1F1F0;",m:"+682 ## ###"},{n:"Costa Rica",c:"+506",e:"&#x1F1E8;&#x1F1F7;",m:"+506 #### ####"},{n:"Croatia",c:"+385",e:"&#x1F1ED;&#x1F1F7;",m:"+385 ## ### ###"},{n:"Cuba",c:"+53",e:"&#x1F1E8;&#x1F1FA;",m:"+53 # ### ####"},{n:"Curacao",c:"+599",e:"&#x1F1E8;&#x1F1FC;",m:""},{n:"Cyprus",c:"+357",e:"&#x1F1E8;&#x1F1FE;",m:"+357 ## ### ###"},{n:"Czechia",c:"+420",e:"&#x1F1E8;&#x1F1FF;",m:""},{n:"Denmark",c:"+45",e:"&#x1F1E9;&#x1F1F0;",m:"+45 ## ## ## ##"},{n:"Djibouti",c:"+253",e:"&#x1F1E9;&#x1F1EF;",m:"+253 ## ## ## ##"},{n:"Dominica",c:"+1767",e:"&#x1F1E9;&#x1F1F2;",m:"+1767 ### ####"},{n:"Dominican Republic",c:"+1",e:"&#x1F1E9;&#x1F1F4;",m:"+1809 ### ####"},{n:"Ecuador",c:"+593",e:"&#x1F1EA;&#x1F1E8;",m:"+593 # ### ####"},{n:"Egypt",c:"+20",e:"&#x1F1EA;&#x1F1EC;",m:"+20 ### ### ####"},{n:"El Salvador",c:"+503",e:"&#x1F1F8;&#x1F1FB;",m:"+503 ## ## ####"},{n:"Equatorial Guinea",c:"+240",e:"&#x1F1EC;&#x1F1F6;",m:"+240 ## ### ####"},{n:"Eritrea",c:"+291",e:"&#x1F1EA;&#x1F1F7;",m:"+291 # ### ###"},{n:"Estonia",c:"+372",e:"&#x1F1EA;&#x1F1EA;",m:"+372 ### ####"},{n:"Ethiopia",c:"+251",e:"&#x1F1EA;&#x1F1F9;",m:"+251 ## ### ####"},
                    {n:"Falkland Islands",c:"+500",e:"&#x1F1EB;&#x1F1F0;",m:"+500 #####"},{n:"Faroe Islands",c:"+298",e:"&#x1F1EB;&#x1F1F4;",m:"+298 ### ###"},{n:"Fiji",c:"+679",e:"&#x1F1EB;&#x1F1EF;",m:"+679 ## #####"},{n:"Finland",c:"+358",e:"&#x1F1EB;&#x1F1EE;",m:"+358 ### ### ## ##"},{n:"France",c:"+33",e:"&#x1F1EB;&#x1F1F7;",m:"+33 ### ### ###"},{n:"French Guiana",c:"+594",e:"&#x1F1EC;&#x1F1EB;",m:""},{n:"French Polynesia",c:"+689",e:"&#x1F1F5;&#x1F1EB;",m:"+689 ## ## ##"},{n:"Gabon",c:"+241",e:"&#x1F1EC;&#x1F1E6;",m:"+241 # ## ## ##"},{n:"Gambia",c:"+220",e:"&#x1F1EC;&#x1F1F2;",m:"+220 ### ## ##"},{n:"Georgia",c:"+995",e:"&#x1F1EC;&#x1F1EA;",m:""},{n:"Germany",c:"+49",e:"&#x1F1E9;&#x1F1EA;",m:"+49 #### ### ####"},{n:"Ghana",c:"+233",e:"&#x1F1EC;&#x1F1ED;",m:"+233 ### ### ###"},{n:"Gibraltar",c:"+350",e:"&#x1F1EC;&#x1F1EE;",m:"+350 ### #####"},{n:"Greece",c:"+30",e:"&#x1F1EC;&#x1F1F7;",m:"+30 ### ### ####"},{n:"Greenland",c:"+299",e:"&#x1F1EC;&#x1F1F1;",m:"+299 ## ## ##"},{n:"Grenada",c:"+1473",e:"&#x1F1EC;&#x1F1E9;",m:"+1473 ### ####"},{n:"Guadeloupe",c:"+590",e:"&#x1F1EC;&#x1F1F5;",m:"+590 ### ### ###"},{n:"Guam",c:"+1671",e:"&#x1F1EC;&#x1F1FA;",m:"+1671 ### ####"},
                    {n:"Guatemala",c:"+502",e:"&#x1F1EC;&#x1F1F9;",m:"+502 # ### ####"},{n:"Guernsey",c:"+44",e:"&#x1F1EC;&#x1F1EC;",m:""},{n:"Guinea",c:"+224",e:"&#x1F1EC;&#x1F1F3;",m:"+224 ## ### ###"},{n:"Guinea-Bissau",c:"+245",e:"&#x1F1EC;&#x1F1FC;",m:"+245 # ######"},{n:"Guyana",c:"+592",e:"&#x1F1EC;&#x1F1FE;",m:"+592 ### ####"},{n:"Haiti",c:"+509",e:"&#x1F1ED;&#x1F1F9;",m:"+509 ## ## ####"},{n:"Honduras",c:"+504",e:"&#x1F1ED;&#x1F1F3;",m:"+504 #### ####"},{n:"Hong Kong",c:"+852",e:"&#x1F1ED;&#x1F1F0;",m:"+852 #### ####"},{n:"Hungary",c:"+36",e:"&#x1F1ED;&#x1F1FA;",m:"+36 ### ### ###"},{n:"Iceland",c:"+354",e:"&#x1F1EE;&#x1F1F8;",m:"+354 ### ####"},{n:"India",c:"+91",e:"&#x1F1EE;&#x1F1F3;",m:"+91 #### ### ###"},{n:"Indonesia",c:"+62",e:"&#x1F1EE;&#x1F1E9;",m:"+62 ## ### ##"},{n:"Iran",c:"+98",e:"&#x1F1EE;&#x1F1F7;",m:"+98 ### ### ####"},{n:"Iraq",c:"+964",e:"&#x1F1EE;&#x1F1F6;",m:"+964 ### ### ####"},{n:"Ireland",c:"+353",e:"&#x1F1EE;&#x1F1EA;",m:"+353 ### ### ###"},{n:"Isle of Man",c:"+44",e:"&#x1F1EE;&#x1F1F2;",m:""},{n:"Israel",c:"+972",e:"&#x1F1EE;&#x1F1F1;",m:"+972 # ### ####"},{n:"Italy",c:"+39",e:"&#x1F1EE;&#x1F1F9;",m:"+39 ### #### ###"},{n:"Ivory Coast",c:"+225",e:"&#x1F1E8;&#x1F1EE;",m:""},
                    {n:"Jamaica",c:"+1",e:"&#x1F1EF;&#x1F1F2;",m:"+1 876 ### ####"},{n:"Japan",c:"+81",e:"&#x1F1EF;&#x1F1F5;",m:"+81 ### ### ###"},{n:"Jersey",c:"+44",e:"&#x1F1EF;&#x1F1EA;",m:""},{n:"Jordan",c:"+962",e:"&#x1F1EF;&#x1F1F4;",m:"+962 # #### ####"},{n:"Kazakhstan",c:"+7",e:"&#x1F1F0;&#x1F1FF;",m:"+7 6## ### ## ##"},{n:"Kenya",c:"+254",e:"&#x1F1F0;&#x1F1EA;",m:"+254 ### ######"},{n:"Kiribati",c:"+686",e:"&#x1F1F0;&#x1F1EE;",m:"+686 ## ###"},{n:"Kosovo",c:"+383",e:"&#x1F1FD;&#x1F1F0;",m:""},{n:"Kuwait",c:"+965",e:"&#x1F1F0;&#x1F1FC;",m:"+965 #### ####"},{n:"Kyrgyzstan",c:"+996",e:"&#x1F1F0;&#x1F1EC;",m:"+996 ### ### ###"},{n:"Laos",c:"+856",e:"&#x1F1F1;&#x1F1E6;",m:"+856 ## ### ###"},{n:"Latvia",c:"+371",e:"&#x1F1F1;&#x1F1FB;",m:"+371 ## ### ###"},{n:"Lebanon",c:"+961",e:"&#x1F1F1;&#x1F1E7;",m:"+961 # ### ###"},{n:"Lesotho",c:"+266",e:"&#x1F1F1;&#x1F1F8;",m:"+266 # ### ####"},{n:"Liberia",c:"+231",e:"&#x1F1F1;&#x1F1F7;",m:"+231 ## ### ###"},{n:"Libya",c:"+218",e:"&#x1F1F1;&#x1F1FE;",m:"+218 ## ### ###"},{n:"Liechtenstein",c:"+423",e:"&#x1F1F1;&#x1F1EE;",m:"+423 ### ### ####"},{n:"Lithuania",c:"+370",e:"&#x1F1F1;&#x1F1F9;",m:"+370 ### ## ###"},
                    {n:"Luxembourg",c:"+352",e:"&#x1F1F1;&#x1F1FA;",m:"+352 ### ### ###"},{n:"Macau",c:"+853",e:"&#x1F1F2;&#x1F1F4;",m:"+853 #### ####"},{n:"Madagascar",c:"+261",e:"&#x1F1F2;&#x1F1EC;",m:"+261 ## ## #####"},{n:"Malawi",c:"+265",e:"&#x1F1F2;&#x1F1FC;",m:"+265 1 ### ###"},{n:"Malaysia",c:"+60",e:"&#x1F1F2;&#x1F1FE;",m:"+60 ### ### ###"},{n:"Maldives",c:"+960",e:"&#x1F1F2;&#x1F1FB;",m:"+960 ### ####"},{n:"Mali",c:"+223",e:"&#x1F1F2;&#x1F1F1;",m:"+223 ## ## ####"},{n:"Malta",c:"+356",e:"&#x1F1F2;&#x1F1F9;",m:"+356 #### ####"},{n:"Marshall Islands",c:"+692",e:"&#x1F1F2;&#x1F1ED;",m:"+692 ### ####"},{n:"Martinique",c:"+596",e:"&#x1F1F2;&#x1F1F6;",m:"+596 ### ## ## ##"},{n:"Mauritania",c:"+222",e:"&#x1F1F2;&#x1F1F7;",m:"+222 ## ## ####"},{n:"Mauritius",c:"+230",e:"&#x1F1F2;&#x1F1FA;",m:"+230 ### ####"},{n:"Mayotte",c:"+262",e:"&#x1F1FE;&#x1F1F9;",m:"+262 ##### ####"},{n:"Mexico",c:"+52",e:"&#x1F1F2;&#x1F1FD;",m:"+52 ### ### ####"},{n:"Micronesia",c:"+691",e:"&#x1F1EB;&#x1F1F2;",m:""},{n:"Moldova",c:"+373",e:"&#x1F1F2;&#x1F1E9;",m:"+373 #### ####"},{n:"Monaco",c:"+377",e:"&#x1F1F2;&#x1F1E8;",m:"+377 ### ### ###"},{n:"Mongolia",c:"+976",e:"&#x1F1F2;&#x1F1F3;",m:"+976 ## ## ####"},
                    {n:"Montenegro",c:"+382",e:"&#x1F1F2;&#x1F1EA;",m:"+382 ## ### ###"},{n:"Montserrat",c:"+1664",e:"&#x1F1F2;&#x1F1F8;",m:"+1664 ### ####"},{n:"Morocco",c:"+212",e:"&#x1F1F2;&#x1F1E6;",m:"+212 ## #### ###"},{n:"Mozambique",c:"+258",e:"&#x1F1F2;&#x1F1FF;",m:"+258 ## ### ###"},{n:"Myanmar",c:"+95",e:"&#x1F1F2;&#x1F1F2;",m:""},{n:"Namibia",c:"+264",e:"&#x1F1F3;&#x1F1E6;",m:"+264 ## ### ####"},{n:"Nauru",c:"+674",e:"&#x1F1F3;&#x1F1F7;",m:"+674 ### ####"},{n:"Nepal",c:"+977",e:"&#x1F1F3;&#x1F1F5;",m:"+977 ## ### ###"},{n:"Netherlands",c:"+31",e:"&#x1F1F3;&#x1F1F1;",m:"+31 ## ### ####"},{n:"New Caledonia",c:"+687",e:"&#x1F1F3;&#x1F1E8;",m:"+687 ## ####"},{n:"New Zealand",c:"+64",e:"&#x1F1F3;&#x1F1FF;",m:"+64 ### ### ###"},{n:"Nicaragua",c:"+505",e:"&#x1F1F3;&#x1F1EE;",m:"+505 #### ####"},{n:"Niger",c:"+227",e:"&#x1F1F3;&#x1F1EA;",m:"+227 ## ## ####"},{n:"Nigeria",c:"+234",e:"&#x1F1F3;&#x1F1EC;",m:"+234 ### ### ####"},{n:"Niue",c:"+683",e:"&#x1F1F3;&#x1F1FA;",m:"+683 ####"},{n:"Norfolk Island",c:"+6723",e:"&#x1F1F3;&#x1F1EB;",m:"+6723## ###"},{n:"North Korea",c:"+850",e:"&#x1F1F0;&#x1F1F5;",m:"+850 #### #############"},{n:"North Macedonia",c:"+389",e:"&#x1F1F2;&#x1F1F0;",m:""},
                    {n:"Northern Mariana Islands",c:"+1670",e:"&#x1F1F2;&#x1F1F5;",m:"+1670 ### ####"},{n:"Norway",c:"+47",e:"&#x1F1F3;&#x1F1F4;",m:"+47 ### ## ###"},{n:"Oman",c:"+968",e:"&#x1F1F4;&#x1F1F2;",m:"+968 ## ### ###"},{n:"Pakistan",c:"+92",e:"&#x1F1F5;&#x1F1F0;",m:"+92 ### ### ####"},{n:"Palau",c:"+680",e:"&#x1F1F5;&#x1F1FC;",m:"+680 ### ####"},{n:"Panama",c:"+507",e:"&#x1F1F5;&#x1F1E6;",m:"+507 ### ####"},{n:"Papua New Guinea",c:"+675",e:"&#x1F1F5;&#x1F1EC;",m:"+675 ### ## ###"},{n:"Paraguay",c:"+595",e:"&#x1F1F5;&#x1F1FE;",m:"+595 ### ### ###"},{n:"Peru",c:"+51",e:"&#x1F1F5;&#x1F1EA;",m:"+51 ### ### ###"},{n:"Philippines",c:"+63",e:"&#x1F1F5;&#x1F1ED;",m:"+63 ### ### ####"},{n:"Poland",c:"+48",e:"&#x1F1F5;&#x1F1F1;",m:"+48 ### ### ###"},{n:"Portugal",c:"+351",e:"&#x1F1F5;&#x1F1F9;",m:"+351 ## ### ####"},{n:"Puerto Rico",c:"+1",e:"&#x1F1F5;&#x1F1F7;",m:""},{n:"Qatar",c:"+974",e:"&#x1F1F6;&#x1F1E6;",m:"+974 #### ####"},{n:"R&#x0351;union",c:"+262",e:"&#x1F1EB;&#x1F1F7;",m:""},{n:"Romania",c:"+40",e:"&#x1F1F7;&#x1F1F4;",m:"+40 ## ### ####"},{n:"Russia",c:"+7",e:"&#x1F1F7;&#x1F1FA;",m:"+7 ### ### ## ##"},{n:"Rwanda",c:"+250",e:"&#x1F1F7;&#x1F1FC;",m:"+250 ### ### ###"},
                    {n:"Saint-Barth&#x0351;lemy",c:"+590",e:"&#x1F1E7;&#x1F1F1;",m:""},{n:"Saint Helena",c:"+290",e:"&#x1F1F8;&#x1F1ED;",m:"+290 ####"},{n:"Saint Kitts & Nevis",c:"+1869",e:"&#x1F1F0;&#x1F1F3;",m:"+1869 ### ####"},{n:"Saint Lucia",c:"+1758",e:"&#x1F1F1;&#x1F1E8;",m:"+1758 ### ####"},{n:"Saint Martin",c:"+590",e:"&#x1F1EB;&#x1F1F7;",m:""},{n:"Saint Pierre & Miquelon",c:"+508",e:"&#x1F1F5;&#x1F1F2;",m:""},{n:"Saint Vincent & Grenadines",c:"+1784",e:"&#x1F1FB;&#x1F1E8;",m:""},{n:"Samoa",c:"+685",e:"&#x1F1FC;&#x1F1F8;",m:"+685 ## ####"},{n:"San Marino",c:"+378",e:"&#x1F1F8;&#x1F1F2;",m:"+378 #### ######"},{n:"S&#x0343;o Tom&#x0351;& Pr&#x0355;ncipe",c:"+239",e:"&#x1F1F8;&#x1F1F9;",m:""},{n:"Saudi Arabia",c:"+966",e:"&#x1F1F8;&#x1F1E6;",m:"+966 # ### ####"},{n:"Senegal",c:"+221",e:"&#x1F1F8;&#x1F1F3;",m:"+221 ## ### ####"},{n:"Serbia",c:"+381",e:"&#x1F1F7;&#x1F1F8;",m:"+381 ## ### ####"},{n:"Seychelles",c:"+248",e:"&#x1F1F8;&#x1F1E8;",m:"+248 # ### ###"},{n:"Sierra Leone",c:"+232",e:"&#x1F1F8;&#x1F1F1;",m:"+232 ## ######"},{n:"Singapore",c:"+65",e:"&#x1F1F8;&#x1F1EC;",m:"+65 #### ####"},{n:"Sint Maarten",c:"+1721",e:"&#x1F1F8;&#x1F1FD;",m:"+1721 ### ####"},
                    {n:"Slovakia",c:"+421",e:"&#x1F1F8;&#x1F1F0;",m:"+421 ### ### ###"},{n:"Slovenia",c:"+386",e:"&#x1F1F8;&#x1F1EE;",m:"+386 ## ### ###"},{n:"Solomon Islands",c:"+677",e:"&#x1F1F8;&#x1F1E7;",m:"+677 #####"},{n:"Somalia",c:"+252",e:"&#x1F1F8;&#x1F1F4;",m:"+252 ## ### ###"},{n:"South Africa",c:"+27",e:"&#x1F1FF;&#x1F1E6;",m:"+27 ## ### ####"},{n:"South Korea",c:"+82",e:"&#x1F1F0;&#x1F1F7;",m:""},{n:"South Sudan",c:"+211",e:"&#x1F1F8;&#x1F1F8;",m:"+211 ## ### ####"},{n:"Spain",c:"+34",e:"&#x1F1EA;&#x1F1F8;",m:"+34 ### ### ###"},{n:"Sri Lanka",c:"+94",e:"&#x1F1F1;&#x1F1F0;",m:"+94 ## ### ####"},{n:"Sudan",c:"+249",e:"&#x1F1F8;&#x1F1E9;",m:"+249 ## ### ####"},{n:"Suriname",c:"+597",e:"&#x1F1F8;&#x1F1F7;",m:"+597 ### ###"},{n:"Svalbard & Jan Mayen",c:"+47",e:"&#x1F1F3;&#x1F1F4;",m:""},{n:"Swaziland",c:"+268",e:"&#x1F1F8;&#x1F1FF;",m:"+268 ## ## ####"},{n:"Sweden",c:"+46",e:"&#x1F1F8;&#x1F1EA;",m:"+46 ## ### ####"},{n:"Switzerland",c:"+41",e:"&#x1F1E8;&#x1F1ED;",m:"+41 ## ### ####"},{n:"Syrian Arab Republic",c:"+963",e:"&#x1F1F8;&#x1F1FE;",m:"+963 ## #### ###"},{n:"Taiwan",c:"+886",e:"&#x1F1F9;&#x1F1FC;",m:"+886 # #### ####"},{n:"Tajikistan",c:"+992",e:"&#x1F1F9;&#x1F1EF;",m:"+992 ## ### ####"},
                    {n:"Tanzania",c:"+255",e:"&#x1F1F9;&#x1F1FF;",m:"+255 ## ### ####"},{n:"Thailand",c:"+66",e:"&#x1F1F9;&#x1F1ED;",m:"+66 ## ### ###"},{n:"Timor-Leste",c:"+670",e:"&#x1F1F9;&#x1F1F1;",m:""},{n:"Togo",c:"+228",e:"&#x1F1F9;&#x1F1EC;",m:"+228 ## ### ###"},{n:"Tokelau",c:"+690",e:"&#x1F1F9;&#x1F1F0;",m:"+690 ####"},{n:"Tonga",c:"+676",e:"&#x1F1F9;&#x1F1F4;",m:"+676 #####"},{n:"Trinidad & Tobago",c:"+1868",e:"&#x1F1F9;&#x1F1F9;",m:"+1868 ### ####"},{n:"Tunisia",c:"+216",e:"&#x1F1F9;&#x1F1F3;",m:"+216 ## ### ###"},{n:"Turkey",c:"+90",e:"&#x1F1F9;&#x1F1F7;",m:"+90 ### ### ####"},{n:"Turkmenistan",c:"+993",e:"&#x1F1F9;&#x1F1F2;",m:"+993 # ### ####"},{n:"Turks & Caicos Islands",c:"+1649",e:"&#x1F1F9;&#x1F1E8;",m:""},{n:"Tuvalu",c:"+688",e:"&#x1F1F9;&#x1F1FB;",m:"+688 2####"},{n:"U.S. Virgin Islands",c:"+1340",e:"&#x1F1FB;&#x1F1EE;",m:""},{n:"Uganda",c:"+256",e:"&#x1F1FA;&#x1F1EC;",m:"+256 ### ### ###"},{n:"Ukraine",c:"+380",e:"&#x1F1FA;&#x1F1E6;",m:"+380 ## ### ## ##"},{n:"United Arab Emirates",c:"+971",e:"&#x1F1E6;&#x1F1EA;",m:"+971 ## ### ####"},{n:"United Kingdom",c:"+44",e:"&#x1F1EC;&#x1F1E7;",m:"+44 ## #### ####"},{n:"United States",c:"+1",e:"&#x1F1FA;&#x1F1F8;",m:""},
                    {n:"Uruguay",c:"+598",e:"&#x1F1FA;&#x1F1FE;",m:"+598 # ### ## ##"},{n:"Uzbekistan",c:"+998",e:"&#x1F1FA;&#x1F1FF;",m:"+998 ## ### ####"},{n:"Vanuatu",c:"+678",e:"&#x1F1FB;&#x1F1FA;",m:"+678 #####"},{n:"Vatican City",c:"+39",e:"&#x1F1FB;&#x1F1E6;",m:"+39 6 698 #####"},{n:"Venezuela",c:"+58",e:"&#x1F1FB;&#x1F1EA;",m:"+58 ### ### ####"},{n:"Vietnam",c:"+84",e:"&#x1F1FB;&#x1F1F3;",m:"+84 ## #### ###"},{n:"Wallis & Futuna",c:"+681",e:"&#x1F1FC;&#x1F1EB;",m:""},{n:"Western Sahara",c:"+212",e:"&#x1F1EA;&#x1F1ED;",m:""},{n:"Yemen",c:"+967",e:"&#x1F1FE;&#x1F1EA;",m:"+967 # ### ###"},{n:"Zambia",c:"+260",e:"&#x1F1FF;&#x1F1F2;",m:"+260 ## ### ####"},{n:"Zimbabwe",c:"+263",e:"&#x1F1FF;&#x1F1FC;",m:"+263 # ######"}],

                modals: {
                    "login": {
                        title: "Вход или регистрация"
                    },
                    "checkCode": {
                        title: "Еднократна парола"
                        // моля, въведете кода, изпратен ви на 886182109
                    },
                }
            }
        },
        computed: {
            modalActive() {
                return this.$store.state.auth.sendCode == null ? "login" : "checkCode"
            },
            sendCodeData() {
                return this.$store.state.auth.sendCode
            },
            checkCodeData() {
                return this.$store.state.auth.checkCode
            }
        },
        methods: {
            ...mapActions([
                'authSendCode',
                'authCheckCode',
                'authClearModal'
            ]),
            open(config) {
                this.clearModal();
                this.isOpen = true;
                this.toCheckout = config !== undefined && config.toCheckout;
            },
            close() {
                this.isOpen = false
            },
            clearErrorSendCode() {
                this.errorSendCode = false;
                this.errorSendCodeText = "";
            },
            submitSendCode() {
                this.clearErrorSendCode();

                if (this.phone.length === 0) {
                    this.errorSendCode = true;
                    this.errorSendCodeText = "Моля проверете мобилния номер";
                    return
                }

                this.sendCode();
            },
            async sendCode() {
                await this.authSendCode({
                    phone: this.phoneCodes[this.phoneCodePosition].c + this.phone
                });
            },
            submitCheckCode() {
                this.checkCode()
            },
            async checkCode() {
                await this.authCheckCode({
                    phone: this.phoneCodes[this.phoneCodePosition].c + this.phone.replace(/\s/g, ''),
                    code: this.code,
                    name: this.name,
                    email: this.email,
                    news_agree: this.newsAgree ? 1 : 0,
                    to_checkout: this.toCheckout
                });
            },
            async clearModal() {
                this.code = "";
                await this.authClearModal();
            }
        },
        watch: {
            checkCodeData: function (newVal) {
                if (newVal != null && !!newVal.token) {
                    this.clearModal();
                    this.isOpen = false;
                }
            }
        }
    }
</script>

<style lang="scss">
    .login-modal {
        padding: 20px;

        .modal-dialog {
            width: 450px;
        }

        .btn {
            width: 100%;
            margin-top: 10px;
        }

    }
</style>
<style lang="scss" scoped>
    @import "../assets/css/base.scss";

    .input-wrapper {
        align-items: center;
        position: relative;
        width: 100%;
        background-color: white;
        height: 56px;
        margin-bottom: 15px;

        &--width125 {
            width: calc(100% - 125px);
        }

        &--height-auto {
            height: auto;
        }

        &__label {
            display: inline-block;
            visibility: visible;
            user-select: none;
            position: absolute;
            color: rgb(131, 130, 135);
            font-size: 14px;
            line-height: 16px;
            pointer-events: none;
            transition: top 0.2s ease 0s, transform 0.2s ease 0s, font-size 0.2s ease 0s;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
            z-index: 1;
        }

        &__input {
            position: relative;
            color: rgb(25, 25, 25);
            line-height: 24px;
            letter-spacing: 0.15px;
            height: 100%;
            width: 100%;
            padding-left: 14px;
            padding-top: 15px;
            padding-right: 40px;
            border: 2px solid #dfdee2;
            border-radius: 4px;
            transition: border-color 0.2s ease 0s, font-size 0.2s ease 0s, letter-spacing 0.2s ease 0s;

            &:focus {
                border: 2px solid $tilda;
            }

            &--error {
                border: 2px solid #DB471E;
            }

            &::-webkit-input-placeholder { /* WebKit browsers */
                color:    #fff;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color:    #fff;
                opacity:  1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color:    #fff;
                opacity:  1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color:    #fff;
            }
        }

        &__input:focus ~ &__label,
        &__input:not(:placeholder-shown) ~ &__label {
            font-size: 12px;
            letter-spacing: 0.4px;
            color: $tilda;
            top: 4px;
            transform: translateY(0px);
        }

        &__error-icon {
            color: #DB471E;
            position: absolute;
            right: 15px;
            top: 14px;
        }

        &__error-text {
            margin-top: 5px;
            margin-right: 10px;
            color: #DB471E;
        }
    }

    .country-list {
        display: none;
        top: 70px;
        left: 16px;
        margin: 8px 0 0 0;
        background: rgb(255, 255, 255);
        box-shadow: rgb(93 62 188 / 20%) 0 4px 24px;
        border-radius: 3px;
        outline: none;
        z-index: 1;
        list-style: none;
        position: absolute;
        padding: 0;
        -webkit-box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
        max-height: 200px;
        overflow-y: scroll;

        &--active {
            display: block;
        }

        .flag {
            padding: 8px;
            cursor: pointer;
            position: relative;

            &__emoji {
                margin-right: 10px;
                display: inline-block;
                font-size: 22px;
                position: relative;
                top: 2px;
                float: left;
            }

            &__name {
                font-size: 15px;
                float: left;
                position: relative;
                top: 1px;
            }

            &__code {
                font-size: 12px;
                float: right;
                position: relative;
                opacity: 0.5;
            }

        }
    }

    .selected-flag {
        width: 115px;
        height: 56px;
        float: left;
        position: relative;
        border: 2px solid #dfdee2;
        border-radius: 4px;
        transition: border-color 0.4s ease 0s;
        margin-right: 10px;


        .material-icons {
            position: absolute;
            left: 68px;
            top: 50%;
            transform: translateY(-50%);
            color: #dfdee2;
            transition: color 0.4s ease 0s;
        }

        &--active {
            border: 2px solid $tilda;

            .material-icons {
                color: $tilda;
            }
        }

        &__emoji {
            position: absolute;
            left: 15px;
            top: 18px;
            font-size: 18px;
        }

        &__code {
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            line-height: 24px;
            color: rgb(62, 62, 62);
            pointer-events: none;
        }
    }

    .custom-control-label::before {
        border: 1px solid #dfdee2 !important;
    }
    .custom-switch .custom-control-label::after {
        background-color: #dfdee2 !important;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #ffffff !important;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #4683e9 !important;
        background-color: #4683e9 !important;
    }
</style>
