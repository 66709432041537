import * as types from '@/store/mutation-types'
import api from '@/services/api/catalog'
import { buildSuccess, handleError } from '@/utils/utils'
import { store } from '@/store'

const getters = {
    menu: (state) => state.menu,
    layout: (state) => state.layout,
};

const actions = {
    catalogMenu({ commit }, payload) {
        if (!store.getters.isGuestTokenSet) {
            return;
        }

        commit(types.FILL_CATALOG_LAYOUT, null);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .catalog({
                    params: {
                        object: 'CATEGORY'
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        if (payload.menu) {
                            for (let i = 0; i < response.data.layout.length; i++) {
                                if (response.data.layout[i].object === "CATEGORY") {
                                    commit(types.FILL_CATALOG_MENU, response.data.layout[i].categories);
                                    response.data.layout.splice(i, 1);
                                }
                            }
                        }

                        if (payload.layout) {
                            commit(types.FILL_CATALOG_LAYOUT, response.data.layout);
                        }

                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    catalogLayout({ commit }, payload) {
        commit(types.FILL_CATALOG_LAYOUT, null);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .catalog(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_LAYOUT, response.data.layout);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {
    [types.FILL_CATALOG_MENU](state, data) {
        state.menu = data
    },
    [types.FILL_CATALOG_LAYOUT](state, data) {
        state.layout = data
    },
};

const state = {
    menu: [],
    layout: []
};

export default {
    state,
    getters,
    actions,
    mutations
}
