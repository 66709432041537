<template>
    <div class="window" v-bind:class="{ 'hidden': !isOpen }" v-cloak>
        <div class="window__info">
            <div class="window__title">Бисквитки</div>
            <div class="window__desc"><p>Тилда използва бисквитки за да персонализира услугите си. Като продължите да използвате сайта, приемате това. Подробности за бисквитките и обработката на вашите данни можете да прочетете в <a target="_blank" href="/legal/privacy_policy">Политика за поверителност</a> и <a target="_blank" href="/legal/cookie_policy">Политика за използване на бисквитки</a>.</p></div>
        </div>
        <div class="window__btns">
            <input type="button" v-on:click="close()" class="btn btn-sm btn-tilda window__btn window__btn--right" value="Ясно">
        </div>
    </div>
</template>


<script>
    export default {
        name: 'CookiesWindow',
        data: function () {
            return {
                isOpen: true
            }
        },
        methods: {
            close: function () {
                window.localStorage.setItem('cookiesAccept', "true");
                this.isOpen = false
            }
        }
    }
</script>