import * as types from '@/store/mutation-types'
import api from '@/services/api/orders'
import { buildSuccess, handleError } from '@/utils/utils'
import { store } from '@/store'

const getters = {
    profileOrders: (state) => state.profileOrders,
    profileOrder: (state) => state.profileOrder,
};

const actions = {
    profileOrders({ commit }, payload) {
        commit(types.FILL_PROFILE_ORDERS, null);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .orders(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_PROFILE_ORDERS, response.data.orders);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    profileOrder({ commit }, payload) {
        commit(types.FILL_PROFILE_ORDER, null);
        commit(types.SHOW_SCREEN_LOADING, true);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .order(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_PROFILE_ORDER, response.data);
                        commit(types.SHOW_SCREEN_LOADING, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    commit(types.SHOW_SCREEN_LOADING, false);
                })
        })
    },
    profileOrderCancel({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .orderCancel(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        location.reload()
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    commit(types.SHOW_SCREEN_LOADING, false);
                })
        })
    },
};

const mutations = {
    [types.FILL_PROFILE_ORDERS](state, data) {
        state.profileOrders = data
    },
    [types.FILL_PROFILE_ORDER](state, data) {
        state.profileOrder = data
    },
};

const state = {
    profileOrders: [],
    profileOrder: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
