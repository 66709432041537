export default [
    {
        path: '/profile/orders',
        name: 'ProfileOrders',
        component: () => import('../../views/profile/Orders.vue')
    },
    {
        path: '/profile/orders/:orderId',
        name: 'ProfileOrder',
        component: () => import('../../views/profile/Order.vue')
    }
]