<template>
    <div class="product">

        <div class="product__content">
            <div class="product__photo">
                <img :src="product.photo.url">
            </div>
            <div class="product__title">
                {{ product.title }} <span class="product__weight">{{ product.weight.text }}</span>
                <div class="clearfix"></div>
                <div class="product__price product__price--bg">
                    <template v-if="product.price.normal !== product.price.special">{{ product.price.special }} лв.</template>
                    <template v-else>{{ product.price.normal }} лв.</template>
                </div>
                <div v-if="product.price.normal !== product.price.special" class="product__price product__price--old">{{ product.price.normal }} лв.</div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="product__info">
            <div class="clearfix"></div>
            <div class="count noselect">
                <div class="count__content">
                    <template v-if="productCart != null">
                        <div class="count__minus"
                             v-on:click="changeCart(false)">
                            <svg width="20" height="20">
                                <use xlink:href="@/assets/images/icons.svg?v=72#icon-minus"></use>
                            </svg>
                        </div>
                        <div class="count__value">{{ quantity }} бр</div>
                        <template v-if="loading">
                            <div class="count__loader">
                                <div class="count__spinner"></div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="count__price">{{ productCart.price_sum.special }} лв</div>
                        </template>
                        <div class="count__plus"
                             :class="{'count__plus--disabled': quantity >= productCart.stock.quantity && cart.address != null }"
                             v-on:click="changeCart(true)">
                            <svg width="20" height="20">
                                <use xlink:href="@/assets/images/icons.svg?v=72#icon-plus"></use>
                            </svg>
                        </div>
                        <div class="clearfix"></div>
                    </template>
                    <template v-else>
                        <div v-if="!loading"
                              class="count__empty" v-on:click="changeCart(true)">
                            <svg width="12" height="12">
                                <use xlink:href="@/assets/images/icons.svg?v=72#icon-basket"></use>
                            </svg> В количката
                        </div>
                        <template v-else>
                            <div class="count__loader count__loader--big">
                                <div class="count__spinner"></div>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="clearfix"></div>
                <template v-if="cart.address != null">
                    <template v-if="cart.slot.next_days === 0 && product.stock.quantity === 0"><div class="count__quantity count__quantity--orange">Само за утре</div></template>
                    <div v-else-if="product.stock.quantity > 0" class="count__quantity">В наличност: <template v-if="product.stock.quantity > 100">много</template><template v-else>{{ product.stock.quantity }} бр.</template></div>
                    <div v-else class="count__quantity count__quantity--red">Няма наличност</div>
                </template>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'SearchProduct',
        props: ['product'],
        data: function () {
            return {
                quantity: 0,
                loading: false
            }
        },
        computed: {
            cart() {
                return this.$store.state.cart.order
            },
            productCart() {
                this.quantity = 0;
                let productCart = null;
                if (this.$store.state.cart.order != null) {
                    for (const p of this.$store.state.cart.order.products) {
                        if (p.id === this.product.id) {
                            productCart = p;
                            this.quantity = p.quantity;
                            break;
                        }
                    }
                }
                return productCart;
            },
            cartChanged() {
                return this.$store.state.cart.cartChanged === null
            },
        },
        methods: {
            ...mapActions([
                'cartProduct',
                'cartProductLoading'
            ]),
            async changeCart(isAdd) {
                await this.cartProductLoading();
                this.loading = true;

                this.quantity = isAdd ? this.quantity + 1 : this.quantity - 1;
                this.changeCartDebounce(this, this.quantity)
            },
            changeCartDebounce: _.debounce((_this, quantity) => _this.changeCartRequest(quantity), 500),
            async changeCartRequest(quantity) {
                if (this.productCart == null) {
                    await this.cartProduct({
                        product_id: this.product.id,
                        stock_type: this.product.stock.type,
                        quantity: 1
                    })
                } else {
                    await this.cartProduct({
                        product_id: this.product.id,
                        stock_type: this.productCart.stock.type,
                        quantity: quantity,
                        line_id: this.productCart.line_id,
                    })
                }
            }
        },
        watch: {
            cartMessage: function (newVal) {
                if (newVal != null) {
                    this.$bvToast.toast(newVal, {
                        variant: "success",
                        toaster: "b-toaster-bottom-right",
                        solid: true,
                        noCloseButton: true
                    });
                }
                this.clearCartMessage();
            },
            cartChanged: function (newVal) {
                if (newVal != null) {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .product {
        padding: 15px 0;
        margin: 0;

        &__photo {
            float: left;
            width: 60px;
            height: 60px;
            margin-right: 20px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 1px solid #F2F2F2;
                border-radius: 12px;
            }
        }

        &__title {
            font-size: 14px;
            line-height: 20px;
            padding-right: 15px;
            margin-bottom: 3px;
            float: left;
            width: calc( 100% - 80px );
        }

        &__weight {
            color: #b0b0b0;
            font-size: 10px;
            white-space: nowrap;
        }

        &__content {
            float: left;
            width: calc(100% - 110px);
        }

        &__info {
            float: right;
            width: 110px;
            text-align: right;
        }

        &__price {
            float: left;
            position: relative;

            &--bg {
                padding: 4px 7px 4px;
                background-color: #f3f3f3;
                font-weight: 700;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;

                &:after, &:before {
                    content: '';
                    display: block;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 50% !important;
                    bottom: auto !important;
                    left: auto;
                    right: auto;
                    padding: 0 !important;
                    margin: 0 !important;
                    -webkit-border-radius: 50% !important;
                    -moz-border-radius: 50% !important;
                    border-radius: 50% !important;
                    z-index: 1;
                    background: #ffffff;
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &:after {
                    right: -4px;
                }

                &:before {
                    left: -4px;
                }
            }

            &--old {
                position: relative;
                margin-left: 10px;
                margin-top: 4px;

                &:before {
                    content: "";
                    position: absolute;
                    top: 9px;
                    width: 100%;
                    height: 2px;
                    display: block;
                    background: #FF3B30;
                    opacity: 0.7;
                    -webkit-transform: rotate(-3deg);
                    transform: rotate(-3deg);
                }
            }
        }
    }

    .count {
        float: right;

        &__content {
            position: relative;
            font-size: 13px;
            margin-top: 3px;
            width: 110px;
            height: 34px;
            background: #4683e9;
            color: #fff;
            border-radius: 10px;
            float: right;
        }

        &__empty {
            line-height: 34px;
            fill: #fff;
            text-align: center;
            font-size: 12px;
            cursor: pointer;

            svg {
                margin-right: 5px;
            }
        }

        &__minus, &__plus {
            float: left;
            width: 35px;
            height: 34px;
            fill: #fff;
            cursor: pointer;
            line-height: 32px;
            text-align: center;
            margin-left: 2px;
            position: relative;
            z-index: 2;
            opacity: 0.7;
            -webkit-transition: opacity .3s;
            -o-transition: opacity .3s;
            -moz-transition: opacity .3s;
            transition: opacity .3s;

            &:hover {
                opacity: 1;
            }

            &--disabled {
                opacity: 0.5 !important;
                cursor: not-allowed;
            }

            svg {
                padding: 3px;
            }
        }

        &__plus {
            float: right;
        }

        &__value {
            position: absolute;
            left: 0;
            top: 0;
            height: 34px;
            width: 100%;
            text-align: center;
            line-height: 21px;
            font-size: 12px;
            z-index: 1;
        }

        &__price {
            position: absolute;
            left: 0;
            top: 0;
            height: 34px;
            width: 100%;
            text-align: center;
            line-height: 50px;
            font-size: 10px;
            opacity: 0.6;
            z-index: 1;
            cursor: default;
        }

        &__quantity {
            text-align: center;
            font-size: 10px;
            color: #B4B4B4;

            &--red {
                color: #FF3B30;
            }

            &--orange {
                color: #F78C07;
            }
        }

        &__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 1;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            pointer-events: none;
            -webkit-transition: visibility 0s ease .2s, opacity .2s ease;
            -o-transition: visibility 0s ease .2s, opacity .2s ease;
            transition: visibility 0s ease .2s, opacity .2s ease;
            color: #ffffff;
            margin-top: 8px;

            &--big {
                margin-top: 2px;
            }
        }

        &__spinner:before {
            border-radius: 50%;
            -webkit-animation: rotating .4s linear infinite;
            animation: rotating .4s linear infinite;
            width: 0.6rem;
            height: 0.6rem;
            border: 0.1rem solid;
            border-right-color: transparent;
            border-bottom-color: transparent;
            opacity: .7;
            display: inline-block;
            content: " ";
        }
    }
</style>