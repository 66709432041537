import axios from 'axios'

export default {
    geocodeByCoordinates(payload) {
        return axios.get('v1/geocode/byCoordinates', payload)
    },
    geocodeBySearch(payload) {
        return axios.get('v1/geocode/bySearch', payload)
    },
    geocodeByPlaceId(payload) {
        return axios.get('v1/geocode/byPlaceId', payload)
    },
}
