<template>
    <div class="header">
        <div class="container-fluid">
            <div class="header__logo">
                <router-link
                        :to="{ name: 'Landing' }">
                    <div class="logo"></div>
                </router-link>
            </div>
            <div class="header__menu">
                <div class="menu">
                    <router-link
                            :to="{ name: 'Catalog' }"
                            class="menu__item menu__item--catalog">
                        <svg width="20" height="20">
                            <use xlink:href="@/assets/images/icons.svg?v=72#ic-header-menu-catalog--v2"></use>
                        </svg> Каталог
                    </router-link>
                    <router-link
                            :to="{ name: 'Delivery' }"
                            class="menu__item">
                        <svg width="20" height="20">
                            <use xlink:href="@/assets/images/icons.svg?v=72#ic-header-menu-delivery--v1"></use>
                        </svg> Доставка
                    </router-link>
                </div>
            </div>
            <div class="header__search" :class="{'header__search--guest': !this.$store.state.auth.isUserTokenSet}">
                <div class="search" v-on-clickaway="searchClickAway">
                    <svg width="18" height="18">
                        <use xlink:href="@/assets/images/icons.svg?v=72#ic-svg-search"></use>
                    </svg>
                    <div class="input-wrapper">
                        <input
                                v-on:keyup="searchChanged()"
                                v-model="search"
                                @focus="isSearchHide = false"
                                type="text"
                                class="search__input input-wrapper__input"
                                :class="{'search__input--focus': !isSearchHide && searchLayout != null && searchLayout.length > 0}"
                                autocomplete="off"
                                placeholder="Найти продукты">
                        <div @click="searchHide(true)" v-if="search != null && search.length > 0 && !isSearching" class="input-wrapper__clear"></div>
                        <div class="search__loader" v-if="isSearching">
                            <div class="search__spinner"></div>
                        </div>
                    </div>
                    <div class="search__products" v-if="!isSearchHide && searchLayout != null && searchLayout.length > 0">
                        <div v-for="l in searchLayout">
                            <SearchLayoutProductList
                                    v-if="l.view === 'PRODUCT_LIST'"
                                    :layout="l"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header__profile" :class="{'header__profile--guest': !this.$store.state.auth.isUserTokenSet}">
                <div class="profile" v-cloak>

                    <div
                            v-if="cart != null"
                            v-on:click="addressModalOpen()"
                            class="profile__item profile__item--address noselect">
                        <span class="material-icons">&#xE569;</span>
                        <div v-if="slot !== null" class="profile__slot">
                            {{ slot.text }}
                            <span><svg fill="none" width="10" height="6"><path d="M9 1L5 5L1 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
                        </div>
                        <div v-if="address !== null" class="profile__address">{{ address.street }}<template v-if="address.house != null && address.house.length > 0">, {{ address.house }}</template></div>
                        <div v-else class="profile__address">Въведете адрес за доставка</div>
                    </div>

                    <div v-on:click="checkout()" class="profile__item profile__item--cart">
                        <svg width="20" height="20"><use xlink:href="@/assets/images/icons.svg?v=72#ic-header-cart-v3"></use></svg>
                        <template v-if="cart !== null"><span>{{ cart.price.without_delivery }} лв</span></template>
                    </div>

                    <div v-if="this.$store.state.auth.isUserTokenSet && user != null" class="profile__item profile__item--profile noselect">
                        <span><span class="material-icons">person</span> Профайл</span>
                        <div class="profile-menu">
                            <div class="profile-menu__header">
                                <div class="profile-menu__header-photo">
                                    <img src="../assets/images/avatar.svg">
                                </div>
                                <div class="profile-menu__header-content">
                                    <div class="profile-menu__header-title">{{ user.name }}</div>
                                    <div class="profile-menu__header-desc">{{ user.email }}</div>
                                    <div class="profile-menu__header-desc">{{ user.phone }}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                            <div class="profile-menu__delim"></div>
                            <router-link :to="{ name: 'ProfileOrders' }" class="profile-menu__item">
                                <svg viewBox="0 0 20 20" width="20" height="20"><path d="M1.625 16.238a1 1 0 01-.628-.928V4.69a1 1 0 011.371-.928l7.97 3.19a1 1 0 01.629.928V18.5a1 1 0 01-1.372.928l-7.97-3.19zm7.342.785V8.557l-5.97-2.39v8.466l5.97 2.39z"></path><path d="M16.937 6.167l-5.97 2.39v8.466l5.97-2.39V6.167zm1.371 10.071l-7.97 3.19a1 1 0 01-1.371-.928V7.88a1 1 0 01.628-.928l7.97-3.19a1 1 0 011.372.928v10.62a1 1 0 01-.629.928z"></path><path d="M4.688 4.69l5.279 2.113 5.279-2.113-5.28-2.113L4.689 4.69zm5.65-4.118l7.97 3.19c.838.335.838 1.521 0 1.856l-7.97 3.19a1 1 0 01-.743 0l-7.97-3.19c-.838-.335-.838-1.521 0-1.856l7.97-3.19a1 1 0 01.743 0z"></path></svg>
                                <span>Моите поръчки</span>
                            </router-link>
                            <!--<a href="/" target="_blank" class="profile-menu__item">-->
                                <!--<svg viewBox="0 0 20 20" width="20" height="20"><path d="M4 4v3h3V4H4zM3 2h5a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1V3a1 1 0 011-1zm0 12a1 1 0 110-2h14a1 1 0 110 2H3zm0 5a1 1 0 110-2h14a1 1 0 110 2H3zm9-15a1 1 0 110-2h5a1 1 0 110 2h-5zm0 5a1 1 0 110-2h5a1 1 0 110 2h-5z"></path></svg>-->
                                <!--<span>Лични данни</span>-->
                            <!--</a>-->
                            <div class="profile-menu__delim"></div>
                            <div @click="logout()" class="profile-menu__item">
                                <svg viewBox="0 0 20 20" width="20" height="20"><path d="M4 16h8a1 1 0 110 2H3a1 1 0 01-1-1V3a1 1 0 011-1h9a1 1 0 110 2H4v12zm12.414-5H7a1 1 0 110-2h9.414l-1.121-1.121a1 1 0 011.414-1.415l2.829 2.829a1 1 0 010 1.414l-2.829 2.829a1 1 0 01-1.414-1.415L16.414 11z"></path></svg>
                                <span>Изход</span>
                            </div>
                        </div>
                    </div>

                    <template v-if="!this.$store.state.auth.isUserTokenSet">
                        <a
                                href="#"
                                v-on:click.prevent="login()"
                                class="profile__item profile__item--login noselect"><span class="material-icons">person</span> Вход</a>
                        <a
                                href="#"
                                v-on:click.prevent="login()"
                                class="profile__item profile__item--login noselect"><span class="material-icons">person_add</span> Регистрация</a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import router from '@/router'
    import _ from 'lodash'
    import { mixin as clickaway } from 'vue-clickaway';
    import SearchLayoutProductList from '@/components/search/LayoutProductList.vue'

    export default {
        mixins: [ clickaway ],
        name: 'Header',
        components: {
            SearchLayoutProductList
        },
        data: function () {
            return {
                search: "",
                isSearchHide: false
            }
        },
        computed: {
            cart() {
                return this.$store.state.cart.order
            },
            address() {
                return this.$store.state.cart.order != null ? this.$store.state.cart.order.address : null
            },
            slot() {
                return this.$store.state.cart.order != null ? this.$store.state.cart.order.slot : null
            },
            user() {
                return this.$store.state.auth.user
            },
            searchLayout() {
                return this.$store.state.search.searchLayout
            },
            isSearching() {
                return this.$store.state.search.isSearching
            }
        },
        props: ['onAddressModalOpen'],
        methods: {
            ...mapActions([
                'authLogout',
                'catalogSearch',
                'catalogSearchReset'
            ]),
            addressModalOpen: function () {
                this.onAddressModalOpen()
            },
            checkout() {
                if (!this.$store.state.auth.isUserTokenSet) {

                    this.$parent.$refs.authModal.open({
                        toCheckout: this.cart != null && this.cart.address != null
                    });

                } else if (this.cart != null && this.cart.address == null) {

                    this.$parent.isAddressModalOpen = true;
                    this.$parent.isAddressModalToCheckout = true;

                } else if (this.cart != null) {
                    window.scrollTo(0,0);

                    router.push({
                        name: 'Checkout'
                    })
                }
            },
            login() {
                this.$parent.$refs.authModal.open();
            },
            async logout() {
                await this.authLogout()
            },

            searchClickAway: function() {
                this.searchHide(false)
            },
            searchHide(clearInput) {
                if (clearInput) {
                    this.search = '';
                    this.catalogSearchReset();
                }
                this.isSearchHide = true
            },

            // Search changed
            searchChanged() {
                this.searchChangedDebounce(this);
            },
            searchChangedDebounce: _.debounce((_this) => {
                _this.searchChangedRequest()
            }, 500),
            async searchChangedRequest() {
                await this.catalogSearch({
                    params: {
                        search: this.search
                    }
                })
            },
        },
        watch: {
            search: function (newVal) {
                if (newVal != null && newVal.length > 0)
                    this.isSearchHide = false
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/css/base';

    .input-wrapper {
        position: relative;

        &__clear {
            width: 32px;
            height: 32px;
            background: transparent no-repeat center url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.2725 5.81705C11.5738 5.51576 11.5738 5.02727 11.2725 4.72597C10.9712 4.42468 10.4827 4.42468 10.1814 4.72597L7.99837 6.90899L5.81721 4.72784C5.51592 4.42654 5.02742 4.42654 4.72613 4.72784C4.42483 5.02913 4.42483 5.51762 4.72613 5.81892L6.90729 8.00008L4.72597 10.1814C4.42468 10.4827 4.42468 10.9712 4.72597 11.2725C5.02727 11.5738 5.51576 11.5738 5.81705 11.2725L7.99837 9.09116L10.1815 11.2743C10.4828 11.5756 10.9713 11.5756 11.2726 11.2743C11.5739 10.973 11.5739 10.4846 11.2726 10.1833L9.08945 8.00008L11.2725 5.81705Z' fill='%23B4B4B4'/%3E%3C/svg%3E");
            position: absolute;
            top: 9px;
            right: 8px;
            -webkit-transition: opacity .3s;
            -o-transition: opacity .3s;
            -moz-transition: opacity .3s;
            transition: opacity .3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            opacity: 0;
            border: 0;
            cursor: pointer;
            z-index: 2;
        }

        &__clear:hover,
        &__input:hover ~ &__clear,
        &__input:focus ~ &__clear {
            opacity: 1;
        }
    }

    .header {
        background: #fff;
        height: 70px;
        box-shadow: 0 1px 17px rgb(0 0 0 / 9%);
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;

        &__logo {
            float: left;
            height: 70px;
            width: 170px;
            margin-top: 17px;
        }

        &__menu {
            float: left;
            width: 300px;
        }

        &__search {
            float: left;
            width: calc( 100% - 960px );
            margin: 10px 40px;

            &--guest {
                width: calc(100% - 1095px);
            }
        }

        &__profile {
            float: right;
            width: 410px;

            &--guest {
                width: 545px;
            }
        }
    }

    .search {
        position: relative;
        width: 100%;
        z-index: 2;
        padding: 0;

        svg {
            position: absolute;
            z-index: 3;
            top: 16px;
            left: 20px;
            fill: #75767D;
        }

        &__input {
            padding: 0 38px 0 48px;
            font-size: 15px;
            color: #212328;
            caret-color: #2DBE64;
            border: 1px solid #F0F0F4;
            background: #F0F0F4;
            border-radius: 12px;
            width: 100%;
            display: block;
            height: 50px;
            line-height: 50px;
            z-index: 2;
            position: relative;

            &--focus, &:focus {
                background: #FFFFFF;
                border: 1px solid #2DBE64;
            }
        }

        &__products {
            height: calc( 100vh - 50px );
            width: 500px;
            background: #FFFFFF;
            position: relative;
            z-index: 1;
            box-shadow: 0 1px 20px rgb(123 123 123 / 15%);
            border-radius: 0 12px 12px 12px;
            padding: 20px 0 0 0;
            margin: -12px 0 0 0;
            overflow: auto;
        }

        &__loader {
            position: absolute;
            top: 18.5px;
            z-index: 3;
            right: 3px;
            line-height: 1;
            transform: translate(-50%, -50%);
            pointer-events: none;
            transition: visibility 0s ease 0.2s, opacity 0.2s ease;
            color: $tilda;
            margin-top: 8px;
        }

        &__spinner:before {
            border-radius: 50%;
            -webkit-animation: rotating .4s linear infinite;
            animation: rotating .4s linear infinite;
            width: 1.3rem;
            height: 1.3rem;
            border: 0.15rem solid;
            border-right-color: transparent;
            border-bottom-color: transparent;
            opacity: .7;
            display: inline-block;
            content: " ";
        }
    }

    .menu {
        overflow: auto;
        height: 36px;
        line-height: 36px;
        margin-top: 17px;
        margin-bottom: 17px;
        margin-left: 40px;

        &__item {
            display: inline-block;
            margin: 0 15px;
            font-size: 16px;
            position: relative;
            padding: 0 5px;
            color: #222;

            &:focus, &:hover {
                color: #222;
            }

            &--catalog {
                color: $tilda !important;
                fill: $tilda;

                &:focus {
                    color: $tilda !important;
                }
            }

            &--active {
                color: $tilda;
            }

            svg {
                position: relative;
                top: -1px;
            }

            &:before {
                content: ' ';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%) scale(0,0);
                background-color: #f9f9f9;
                border-radius: 4px;
                transition: all .2s ease;
                width: calc(100% - -10px);
                height: 105%;
                z-index: -1
            }

            &:hover:before{
                transform: translate(-50%,-50%) scale(1,1);
            }
        }

    }

    .profile {
        float: right;
        margin-top: 13px;

        &__item {
            height: 44px;
            padding: 7px 16px 7px 40px;
            display: inline-block;
            font-size: 15px;
            background: transparent;
            line-height: 30px;
            white-space: nowrap;
            border-radius: 12px;
            position: relative;
            cursor: pointer;
            -webkit-box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
            -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
            box-shadow: 0 2px 15px rgb(0 0 0 / 5%);
            float: left;

            .material-icons {
                font-size: 18px;
                position: absolute;
                left: 12px;
                top: 12px;
            }

            &--login {
                margin-left: 20px;
            }

            &--address {
                line-height: 16px;
                color: #4683e9;
                border: 1px solid #4783e9;
                border-radius: 12px 0 0 12px;
                width: 210px;
            }

            &--profile {
                margin-left: 20px;

                &:hover .profile-menu {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                    -webkit-transform: translateZ(0) scale(1);
                    transform: translateZ(0) scale(1);
                }

                .material-icons {
                    font-size: 18px;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    opacity: 0.4;
                }
            }

            &--cart {
                padding: 7px 7px;
                margin-left: -10px;
                color: #fff;
                height: 50px;
                width: 60px;
                margin-top: -3px;
                background-image: -webkit-linear-gradient(148.6deg, $tilda 0%, #265bb3 100%);
                background-image: -moz-linear-gradient(148.6deg, $tilda 0%, #265bb3 100%);
                background-image: -o-linear-gradient(148.6deg, $tilda 0%, #265bb3 100%);
                background-image: linear-gradient(148.6deg, $tilda 0%, #265bb3 100%);
                text-align: center;
                line-height: 22px;

                svg {
                    stroke: #ffffff;
                    margin-top: -5px;
                }

                span {
                    display: block;
                    font-size: 10px;
                    line-height: 15px;
                    margin-top: 2px;
                }
            }
        }

        &__slot {
            font-weight: 700;

            svg {
                stroke: $tilda;
                margin-left: 2px;
            }
        }

        &__address {
            color: #949494;
            font-size: 12px;
            max-width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .profile-menu {
        position: absolute;
        width: 250px;
        right: -1px;
        top: -1px;
        background: #fff;
        box-shadow: 0 2px 10px 0 rgba(46, 49, 53, 0.2);
        border-radius: 6px;
        font-size: 14px;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: translate3d(0, 10px, 0) scale(1);
        transform: translate3d(0, 10px, 0) scale(1);
        -webkit-transition: visibility 0.5s linear 0s, opacity 0.2s ease, -webkit-transform 0.3s cubic-bezier(0.175, 0.885, 0.135, 1.425);
        transition: visibility 0.5s linear 0s, opacity 0.2s ease, transform 0.3s cubic-bezier(0.175, 0.885, 0.135, 1.425);
        padding: 5px 0;
        font-weight: 400;

        &__item {
            height: 38px;
            line-height: 38px;
            padding: 0 15px;
            display: block;
            color: #222 !important;
            position: relative;

            &:hover {
                background: #f5f5f5;
            }

            svg {
                fill: $tilda;
                position: relative;
                top: -2px;
            }

            span {
                margin-left: 10px;
            }
        }

        &__header {
            margin: 10px 15px 0 15px;
            padding-bottom: 10px;

            &-photo {
                width: 40px;
                height: 40px;
                float: left;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                }
            }

            &-content {
                float: left;
                margin-top: 1px;
                margin-left: 15px;
                line-height: 14px;
            }

            &-title {
                line-height: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &-desc {
                font-size: 12px;
                color: #898f97;
                margin-top: 3px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &__delim {
            height: 1px;
            background: #e6e6e6;
            margin: 5px 15px;
        }
    }

    .logo {
        width: 170px;
        height: 36px;
        background-size: 170px 36px;
        background-image: url('../assets/images/logo.svg');
    }
</style>