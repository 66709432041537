export default [
    {
        path: '/catalog',
        name: 'Catalog',
        component: () => import('../../views/catalog/Catalog.vue')
    },
    {
        path: '/category/:categorySlug',
        name: 'CatalogCategory',
        component: () => import('../../views/catalog/Catalog.vue')
    },
    {
        path: '/promotion/:promotionSlug',
        name: 'CatalogPromotion',
        component: () => import('../../views/catalog/Catalog.vue')
    },
]