import * as types from '@/store/mutation-types'
import apiAddress from '@/services/api/address'
import apiCart from '@/services/api/cart'
import { buildSuccess, handleError } from '@/utils/utils.js'
import { store } from '@/store'
import router from '@/router'

const getters = {

};

const actions = {
    checkoutAddress({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            apiAddress
                .addressesUpdate(payload.address)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    },
    checkoutComment({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            apiCart
                .cartUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    checkoutOrder({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            apiCart
                .cartCheckout(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SHOW_SCREEN_LOADING, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            router.push({
                                name: 'ProfileOrder',
                                params: {
                                    orderId: response.data.order_id,
                                    congratulations: true
                                }
                            })
                        );
                        window.scrollTo(0,0);
                        store.dispatch('cartGet');
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    },
};

const mutations = {

};

const state = {

};

export default {
    state,
    getters,
    actions,
    mutations
}