import Vue from 'vue'
import axios from 'axios'

axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        // If request is different than any of the URLS in urlsExcludedForBearerHeader
        // then send Authorization header with token from localStorage
        const urlsExcludedForBearerHeader = [
            'v1/auth/guest'
        ];
        if (urlsExcludedForBearerHeader.indexOf(config.url) === -1) {
            let isGuestTokenSet = !!localStorage.getItem('guestToken') && localStorage.getItem('guestToken') !== "undefined";
            let isUserTokenSet = !!localStorage.getItem('userToken') && localStorage.getItem('userToken') !== "undefined";

            let token = "";
            if (isUserTokenSet) {
                token = localStorage.getItem('userToken')
            } else if (isGuestTokenSet) {
                token = localStorage.getItem('guestToken')
            }

            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error)
    }
);

// // Add a response interceptor
// axios.interceptors.response.use(
//     (response) => {
//         return response
//     },
//     (error) => {
//         // Do something with response error
//         return Promise.reject(error)
//     }
// )

// eslint-disable-next-line no-shadow
Plugin.install = (Vue) => {
    Vue.axios = axios;
    window.axios = axios;
    Object.defineProperties(Vue.prototype, {
        axios: {
            get() {
                return axios
            }
        },
        $axios: {
            get() {
                return axios
            }
        }
    })
};

Vue.use(Plugin);

export default Plugin
