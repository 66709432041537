import * as types from '@/store/mutation-types'
import api from '@/services/api/address'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
    createdAddress: (state) => state.createdAddress,
    updatedAddress: (state) => state.updatedAddress,
};

const actions = {
    addressCreate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .addressesCreate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_ADDRESS_CREATE, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    addressUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .addressesUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.SHOW_SCREEN_LOADING, false);
                        commit(types.FILL_ADDRESS_UPDATE, response.data);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    }
};

const mutations = {
    [types.FILL_ADDRESS_CREATE](state, data) {
        state.createdAddress = data;
    },
    [types.FILL_ADDRESS_UPDATE](state, data) {
        state.updatedAddress = data;
    }
};

const state = {
    createdAddress: null,
    updatedAddress: null
};

export default {
    state,
    getters,
    actions,
    mutations
}