import * as types from '@/store/mutation-types'
import router from '@/router'
import api from '@/services/api/auth'
import { buildSuccess, handleError } from '@/utils/utils.js'
import { store } from '@/store'

const getters = {
    user: (state) => state.user,
    isGuestTokenSet: (state) => state.isGuestTokenSet,
    isUserTokenSet: (state) => state.isUserTokenSet,
    sendCode: (state) => state.sendCode,
    checkCode: (state) => state.checkCode
};

const actions = {
    authGuest({ commit }) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .authGuest()
                .then((response) => {
                    if (response.status === 200) {
                        window.localStorage.setItem('guestToken', response.data.token);
                        commit(types.SAVE_GUEST_TOKEN, response.data.token);
                        commit(types.SHOW_SCREEN_LOADING, false);

                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            commit(types.FILL_TOKEN)
                        );

                        router.go()
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    authSendCode({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .authSendCode(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_AUTH_SEND_CODE, response.data);
                        commit(types.SHOW_SCREEN_LOADING, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    },
    authClearModal({ commit }, payload) {
        commit(types.FILL_AUTH_SEND_CODE, null);
        commit(types.FILL_AUTH_CHECK_CODE, null);
    },
    authCheckCode({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .authCheckCode(payload)
                .then((response) => {
                    if (response.status === 200) {
                        window.localStorage.setItem('user', JSON.stringify(response.data.user));
                        window.localStorage.setItem('userToken', response.data.token);
                        commit(types.SAVE_USER, response.data.user);
                        commit(types.SAVE_USER_TOKEN, response.data.token);
                        commit(types.FILL_AUTH_CHECK_CODE, response.data);
                        commit(types.SHOW_SCREEN_LOADING, false);

                        buildSuccess(
                            null,
                            commit,
                            resolve,
                            commit(types.FILL_TOKEN)
                        );

                        if (payload.to_checkout === true) {
                            router.push({
                                name: 'Checkout'
                            })
                        }
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    },
    autoLogin({ commit }) {
        commit(types.SAVE_USER, JSON.parse(localStorage.getItem('user')));
        commit(types.SAVE_USER_TOKEN, localStorage.getItem('userToken'));
        commit(types.SAVE_GUEST_TOKEN, localStorage.getItem('guestToken'));
        commit(types.FILL_TOKEN);
    },
    authLogout({ commit }) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('userToken');
        commit(types.LOGOUT);
        router.push({
            name: 'Landing'
        })
    },
    // authLogout401({ commit }) {
    //     window.localStorage.removeItem('user');
    //     window.localStorage.removeItem('userToken');
    //     commit(types.LOGOUT);
    //     // работает ли так?
    //     router.push({
    //         name: 'Landing'
    //     })
    // }
};

const mutations = {
    [types.SAVE_GUEST_TOKEN](state, token) {
        state.isGuestTokenSet = !!token && token !== "undefined";
    },
    [types.SAVE_USER_TOKEN](state, token) {
        state.isUserTokenSet = !!token && token !== "undefined";
    },
    [types.SAVE_USER](state, user) {
        state.user = user
    },
    [types.FILL_TOKEN]() {
        store.dispatch('cartGet')
    },
    [types.LOGOUT](state) {
        state.user = null;
        state.isUserTokenSet = false;
        store.dispatch('cartGet')
    },
    [types.FILL_AUTH_SEND_CODE](state, data) {
        state.sendCode = data;
    },
    [types.FILL_AUTH_CHECK_CODE](state, data) {
        state.checkCode = data;
    },
};

const state = {
    user: JSON.parse(localStorage.getItem('user')),
    isGuestTokenSet: !!localStorage.getItem('guestToken') && localStorage.getItem('guestToken') !== "undefined",
    isUserTokenSet: !!localStorage.getItem('userToken') && localStorage.getItem('userToken') !== "undefined",
    sendCode: null,
    checkCode: null
};

export default {
    state,
    getters,
    actions,
    mutations
}