import axios from 'axios'
import qs from 'qs';

export default {
    payments(payload) {
        return axios.get('v1/payments', payload)
    },
    paymentsAdd(payload) {
        return axios.post('v1/payments/add', payload)
    },
    paymentsDefault(payload) {
        return axios.get('v1/payments/' + payload.id + '/default')
    },
    paymentsDelete(payload) {
        return axios.get('v1/payments/' + payload.id + '/delete')
    },
}
