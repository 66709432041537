// error
export const SHOW_ERROR = 'SHOW_ERROR';
export const ERROR = 'ERROR';
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS';
export const SUCCESS = 'SUCCESS';
// loading
export const SHOW_LOADING = 'SHOW_LOADING';
export const SHOW_SCREEN_LOADING = 'SHOW_SCREEN_LOADING';
// auth
export const SAVE_GUEST_TOKEN = 'SAVE_GUEST_TOKEN';
export const SAVE_USER_TOKEN = 'SAVE_USER_TOKEN';
export const SAVE_USER = 'SAVE_USER';
export const LOGOUT = 'LOGOUT';
export const FILL_AUTH_SEND_CODE = 'FILL_AUTH_SEND_CODE';
export const FILL_AUTH_CHECK_CODE = 'FILL_AUTH_CHECK_CODE';
export const FILL_TOKEN = 'FILL_TOKEN';
// cart
export const FILL_CART = 'FILL_CART';
export const FILL_CART_ADDRESS_SAVED = 'FILL_CART_ADDRESS_SAVED';
export const FILL_CART_CHANGED = 'FILL_CART_CHANGED';
export const FILL_CART_CONFIG = 'FILL_CART_CONFIG';
export const FILL_CART_MESSAGE = 'FILL_CART_MESSAGE';
export const FILL_CART_SLOTS = 'FILL_CART_SLOTS';
// catalog
export const FILL_CATALOG_LAYOUT = 'FILL_CATALOG_LAYOUT';
export const FILL_CATALOG_MENU = 'FILL_CATALOG_MENU';
export const FILL_CATALOG_SEARCH = 'FILL_CATALOG_SEARCH';
export const SHOW_LOADING_CATALOG_SEARCH = 'SHOW_LOADING_CATALOG_SEARCH';
// geocode
export const FILL_GEOCODE_ADDRESS = 'FILL_GEOCODE_ADDRESS';
export const FILL_GEOCODE_SEARCH = 'FILL_GEOCODE_SEARCH';
export const FILL_GEOCODE_ADDRESS_BY_SEARCH = 'FILL_GEOCODE_ADDRESS_BY_SEARCH';
// address
export const FILL_ADDRESS_CREATE = 'FILL_ADDRESS_CREATE';
export const FILL_ADDRESS_UPDATE = 'FILL_ADDRESS_UPDATE';
// profile orders
export const FILL_PROFILE_ORDERS = 'FILL_PROFILE_ORDERS';
export const FILL_PROFILE_ORDER = 'FILL_PROFILE_ORDER';
// profile payments
export const FILL_PROFILE_PAYMENTS = 'FILL_PROFILE_PAYMENTS';
