<template>
    <b-modal v-model="isOpen"
             hide-footer
             title="Посочете адрес и време за доставка"
             modal-class="address-modal"
             size="md"
             @hidden="close">
        <div>
            <div class="address-modal__top">
                <div class="address-modal__top-content">

                    <div v-on:click="getGeoLocation()"
                         class="address-modal__location">
                        <i class="material-icons">&#xE569;</i> Намери ме
                    </div>

                    <div class="address-modal__address">
                        <div v-if="!searchMode"
                             class="address-modal__address-text"
                             v-on:click="inputFocus()">

                            <template v-if="this.$store.state.loading.showLoading">
                                <b-progress value="100" max="100" show-progress animated></b-progress>
                            </template>
                            <span v-if="address !== null">{{ address.address }}</span>
                        </div>

                        <div v-if="searchMode" class="address-modal__search">

                            <input id="address-modal__address-input"
                                   class="address-modal__address-input form-control"
                                   v-model="searchAddress"
                                   v-on:keyup="search"
                                   ref="search"
                                   autocomplete="off"
                                   autofocus>

                            <div v-if="searchAddress !== null && searchAddress.length > 0"
                                 class="address-modal__search-close"
                                 v-on:click="inputClear()">
                                <svg viewBox="0 0 15 15" style="width: 15px; height: 15px; display: inline-block; user-select: none; fill: black;"><path d="M7.5 6.58L1.742.824a.4.4 0 0 0-.565 0l-.354.354a.4.4 0 0 0 0 .565L6.581 7.5.823 13.258a.4.4 0 0 0 0 .565l.354.354a.4.4 0 0 0 .565 0L7.5 8.419l5.758 5.758a.4.4 0 0 0 .565 0l.354-.354a.4.4 0 0 0 0-.565L8.419 7.5l5.758-5.758a.4.4 0 0 0 0-.565l-.354-.354a.4.4 0 0 0-.565 0L7.5 6.581z"></path></svg>
                            </div>

                            <div v-if="predictions.length > 0" class="address-modal__search-container">
                                <div v-on:click="predictionSelect(prediction)"
                                     v-for="prediction in predictions"
                                     class="address-modal__prediction">

                                    <div class="address-modal__prediction-title">{{ prediction.main_text }}</div>
                                    <div class="address-modal__prediction-desc">{{ prediction.secondary_text }}</div>
                                </div>
                            </div>

                        </div>

                        <div v-if="!searchMode"
                                class="address-modal__address-close"
                                v-on:click="inputFocus()">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                 width="22" height="22"
                                 viewBox="0 0 30 30"
                                 style=" fill:#ffffff;"><path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 19 6 L 24 11 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 17 8 L 5.2597656 19.740234 C 5.2597656 19.740234 6.1775313 19.658 6.5195312 20 C 6.8615312 20.342 6.58 22.58 7 23 C 7.42 23.42 9.6438906 23.124359 9.9628906 23.443359 C 10.281891 23.762359 10.259766 24.740234 10.259766 24.740234 L 22 13 L 17 8 z M 4 23 L 3.0566406 25.671875 A 1 1 0 0 0 3 26 A 1 1 0 0 0 4 27 A 1 1 0 0 0 4.328125 26.943359 A 1 1 0 0 0 4.3378906 26.939453 L 4.3632812 26.931641 A 1 1 0 0 0 4.3691406 26.927734 L 7 26 L 5.5 24.5 L 4 23 z"></path></svg>
                        </div>

                    </div>

                    <div class="clearfix"></div>

                </div>

                <div class="address-modal__hint">Поставете маркера точно върху вашия адрес.<br>Така куриерът ще ви намери по-бързо.</div>

            </div>

            <div class="address-modal__map">
                <GmapMap
                        ref="addressMap"
                        :zoom="zoom"
                        :center="center"
                        :options="{
                        mapTypeControl: false,
                        streetViewControl: false,
                        fullscreenControl: false
                        }"
                        @center_changed="centerMapChanged"
                        class="address-modal__map-js"/>

                <div class="address-modal__marker">
                    <img src="../assets/images/pin.png">
                </div>

                <div v-if="this.$store.state.error.showErrorMessage" class="address-modal__alert alert alert-danger" role="alert">
                    {{ this.$store.state.error.errorMessage }}
                </div>

                <div v-if="!isDeliveryPossible" class="address-modal__alert alert alert-danger" role="alert">
                    За съжаление не доставяме тук
                </div>
            </div>

            <div class="delivery">
                <div class="delivery__title">Кога искате да доставим продукти?</div>

                <div class="delivery__info noselect">
                    <div v-if="slots != null" class="input-wrapper">
                        <b-form-select v-model="slotActive" :options="slotsOptions" class="input-wrapper__input"></b-form-select>
                        <label class="input-wrapper__label">Ден и час</label>
                    </div>
                    <b-skeleton v-else type="button" width="100%" height="56px"></b-skeleton>
                </div>
            </div>

            <div class="address-modal__btn"
                 v-bind:class="{ 'address-modal__btn--not-possible': !isDeliveryPossible,
                                        'address-modal__btn--disabled': addressStreet === null || addressStreet.length === 0 }"
                 v-on:click="confirm()">

                <template v-if="this.$store.state.loading.showLoading">
                    <b-progress :variant="isDeliveryPossible ? 'primary' : 'danger'" value="100" max="100" show-progress animated></b-progress>
                </template>
                <span>Запази</span>
            </div>

        </div>
    </b-modal>
</template>

<script>
    import _ from 'lodash'
    import { gmapApi } from 'gmap-vue'
    import { mapActions } from 'vuex'
    import router from '@/router'

    export default {
        name: 'AddressModal',
        props: ['onAddressModalClose', 'isAddressModalOpen', 'isAddressModalToCheckout'],
        computed: {
            google: gmapApi,
            address() {
                return this.isDeliveryPossible ? this.$store.state.geocode.address : null
            },
            addressStreet() {
                return this.isDeliveryPossible && this.$store.state.geocode.address != null ? this.$store.state.geocode.address.address : null
            },
            predictions() {
                return this.$store.state.geocode.predictions
            },
            addressBySearch() {
                return this.$store.state.geocode.addressBySearch
            },
            createdAddress() {
                return this.$store.state.address.createdAddress
            },
            cart() {
                return this.$store.state.cart.order
            },
            addressSaved() {
                return this.$store.state.cart.addressSaved
            },
            slots() {
                this.slotsOptions = []
                if (this.$store.state.cart.slots != null) {
                    for (let i=0; i<this.$store.state.cart.slots.length; i++) {
                        let o = {
                            value: i,
                            text: this.$store.state.cart.slots[i].text
                        };

                        if (this.$store.state.cart.slots[i].time_from === this.cart.slot.time_from) {
                            this.slotActive = i;
                        }

                        this.slotsOptions.push(o)
                    }
                }

                return this.$store.state.cart.slots
            }
        },
        data: function () {
            return {
                isOpen: this.isAddressModalOpen,
                toCheckout: this.isAddressModalToCheckout,

                map: undefined,
                center: {
                    lat: 42.55938751729416,
                    lng: 27.52141239539795
                },
                zoom: 13,

                ignoreMoving: false,
                searchMode: false,
                searchAddress: '',

                polygon: undefined,
                isDeliveryPossible: true,

                slotsOptions: [],
                slotActive: null
            }
        },
        watch: {
            isAddressModalOpen: function (newVal) {
                this.toCheckout = false;
                this.isOpen = newVal;
            },
            isAddressModalToCheckout: function (newVal) {
                this.toCheckout = newVal;
            },
            addressBySearch: function (newVal) {
                this.searchMode = false;
                this.ignoreMoving = true;
                this.map.panTo(new this.google.maps.LatLng(newVal.lat, newVal.lng));
            },
            createdAddress: function (newVal) {
                  this.setCartAddress(newVal);
            },
            addressSaved: function () {
                this.close();
                if (this.toCheckout === true) {
                    router.push({
                        name: 'Checkout'
                    });
                } else {
                    router.go()
                }
            }
        },
        async mounted() {
            await this.$gmapApiPromiseLazy();
            let self = this;
            this.$nextTick(() => {
                this.$refs.addressMap.$mapPromise.then(function (map) {
                    self.map = map;
                    self.addressMapInit();
                });
            });

            this.getSlots();
        },
        methods: {
            ...mapActions([
                'geocodeByCoordinates',
                'geocodeBySearch',
                'geocodeByPlaceId',
                'cartUpdate',
                'cartSlots',
                'addressCreate'
            ]),
            close() {
                this.onAddressModalClose()
            },
            getGeoLocation() {
                navigator.geolocation.getCurrentPosition(res => {
                    this.map.panTo(new this.google.maps.LatLng(res.coords.latitude, res.coords.longitude));
                    this.map.setZoom(17);
                }, err => {
                    console.log(err.message);
                });
            },
            addressMapInit() {
                let isAddressExist = false;

                // если есть адрес у пользователя
                if (this.cart != null && this.cart.address != null) {
                    this.map.panTo(new this.google.maps.LatLng(this.cart.address.lat, this.cart.address.lng));
                    this.map.setZoom(17);
                } else {
                    this.getGeoLocation();
                }

                let coords = [
                    new this.google.maps.LatLng(42.55574, 27.5126),
                    new this.google.maps.LatLng(42.55802, 27.51457),
                    new this.google.maps.LatLng(42.56206, 27.51659),
                    new this.google.maps.LatLng(42.56415, 27.51843),
                    new this.google.maps.LatLng(42.56554, 27.52165),
                    new this.google.maps.LatLng(42.56614, 27.52412),
                    new this.google.maps.LatLng(42.56581, 27.5283),
                    new this.google.maps.LatLng(42.56442, 27.53479),
                    new this.google.maps.LatLng(42.56295, 27.5376),
                    new this.google.maps.LatLng(42.56067, 27.54686),
                    new this.google.maps.LatLng(42.55922, 27.55281),
                    new this.google.maps.LatLng(42.55667, 27.54652),
                    new this.google.maps.LatLng(42.5575, 27.54282),
                    new this.google.maps.LatLng(42.5589, 27.54083),
                    new this.google.maps.LatLng(42.5593, 27.53742),
                    new this.google.maps.LatLng(42.55817, 27.53332),
                    new this.google.maps.LatLng(42.55853, 27.53039),
                    new this.google.maps.LatLng(42.55877, 27.5278),
                    new this.google.maps.LatLng(42.55613, 27.52039),
                    new this.google.maps.LatLng(42.55467, 27.51645),
                    new this.google.maps.LatLng(42.55397, 27.51466)
                ];

                this.polygon = new this.google.maps.Polygon({
                    map: this.map,
                    paths: coords,
                    strokeColor: '#4683e9',
                    strokeOpacity: 0.3,
                    strokeWeight: 2,
                    fillColor: '#4683e9',
                    fillOpacity: 0.2,
                    draggable: false,
                    geodesic: false
                });

                if (!isAddressExist) {
                    this.centerMapChanged();
                }
            },
            centerMapChanged() {
                console.log("centerMapChanged");
                this.searchMode = false;
                if (!this.ignoreMoving) {
                    this.$store.state.loading.showLoading = true;
                }
                this.centerMapChangedDebounce(this)
            },
            centerMapChangedDebounce: _.debounce((_this) => {
                console.log("centerMapChangedDebounced");

                _this.isDeliveryPossible = _this.google.maps.geometry.poly.containsLocation(_this.map.getCenter(), _this.polygon);

                if (!_this.isDeliveryPossible) {
                    _this.searchAddress = "";
                    _this.$store.state.loading.showLoading = false;
                    return;
                }

                if (_this.ignoreMoving) {
                    _this.ignoreMoving = false;
                    return;
                }

                _this.getAddressByCoordinate();
            }, 500),
            async getAddressByCoordinate() {
                await this.geocodeByCoordinates({
                    params: {
                        lat: this.map.getCenter().lat(),
                        lng: this.map.getCenter().lng()
                    }
                })
            },
            async getAddressByPlaceId(prediction) {
                await this.geocodeByPlaceId({
                    params: {
                        place_id: prediction.place_id
                    }
                })
            },
            async setCartAddress(address) {
                await this.cartUpdate({
                    address_id: address.id,
                    slot_time_from: this.slots[this.slotActive].time_from,
                    slot_time_to: this.slots[this.slotActive].time_to,
                });
            },
            inputFocus(search) {
                this.searchMode = true;
                this.searchAddress = search != null ? search : (this.address != null ? this.address.address : "");

                let _this = this;
                setTimeout(function () {
                    _this.$refs.search.focus()
                }, 200);
            },
            inputClear() {
                this.inputFocus('')
            },

            search() {
                this.searchDebounce(this, this.searchAddress);
            },
            searchDebounce: _.debounce((_this, search) => {
                _this.getAddressBySearch(search)
            }, 500),
            async getAddressBySearch(search) {
                await this.geocodeBySearch({
                    params: {
                        search: search
                    }
                })
            },

            predictionSelect(prediction) {
                this.$store.state.geocode.predictions = [];
                this.searchMode = true;

                this.getAddressByPlaceId(prediction)
            },

            async getSlots() {
                await this.cartSlots();
            },

            async confirm() {
                if (this.$store.state.loading.showLoading ||
                    this.address === null || !this.isDeliveryPossible)
                {
                    return;
                }

                await this.addressCreate({
                    geocode_id: this.address.id
                })
            }
        }
    }
</script>

<style>
    .address-modal .modal-dialog {
        max-width: 600px !important;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/css/base.scss";

    .address-modal {
        padding: 20px;

        &__top {
            margin-bottom: 5px;

            &-content {
                height: 45px;
            }
        }

        &__hint {
            margin-top: 15px;
            margin-bottom: 15px;
            background: #fff286;
            font-size: 16px;
            padding: 12px;
            border-radius: 5px;
            text-align: center;
        }

        &__map {
            height: 400px;
            border-radius: 10px;
            overflow: hidden;
            position: relative;

            &-js {
                height: 100%;
                width: 100%;
                z-index: 2;
                position: absolute;
            }
        }

        &__marker {
            width: 45px;
            height: 63px;
            margin-top: -60px;
            margin-left: -22.5px;
            top: 50%;
            left: 50%;
            position: absolute;
            z-index: 3;

            img {
                height: 100%;
                width: 100%;
            }
        }

        &__alert {
            width: 200px;
            left: calc(50% - 100px);
            bottom: 20px;
            position: absolute !important;
            text-align: center;
            z-index: 3;
        }

        &__address {
            position: relative;
            float: left;
            width: calc(100% - 150px);
            height: 45px;

            &-close {
                cursor: pointer;
                position: absolute;
                top: 12px;
                right: 12px;
            }

            &-text {
                height: 45px;
                line-height: 18px;
                font-size: 16px;
                font-weight: 500;
                padding-right: 45px;
                padding-left: 20px;
                color: #fff;
                background: $tilda;
                border-radius: 0 3px 3px 0;
                display: flex;
                justify-content: stretch;
                align-items: center;
                cursor: pointer;
                position: relative;
            }

            &-input {
                height: 45px !important;
                line-height: 45px !important;
                background: #fff !important;
                border-radius: 0 3px 3px 0 !important;
                border-left: 0 !important;
                padding-right: 30px !important;
            }
        }

        &__search {

            &-close {
                cursor: pointer;
                position: absolute;
                top: 12px;
                right: 12px;
            }

            &-container {
                box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.16);
                position: absolute;
                width: calc(100% - 35px);
                z-index: 5;
                margin-top: -1px;
                margin-left: 0;
                border-top: 0;
            }
        }

        &__prediction {
            cursor: pointer;
            padding: 8px 15px;
            background-color: #ffffff;
            border-bottom: 1px solid #f5f3f3;

            &:hover {
                background-color: #f4f4f4;
            }

            &-desc {
                font-size: 12px;
                color: #949494;
            }
        }

        &__location {
            float: left;
            width: 150px;
            height: 45px;
            line-height: 45px;
            background: #fff;
            color: #222;
            border: 1px solid $tilda;
            border-radius: 3px 0 0 3px;
            font-size: 17px;
            font-weight: 500;
            text-align: center;
            cursor: pointer;

            i {
                position: relative;
                top: 5px;
            }
        }

        &__btn {
            width: 100%;
            height: 45px;
            margin-top: 20px;
            line-height: 45px;
            font-size: 19px;
            font-weight: 500;
            color: #fff;
            background: $tilda;
            border-radius: 3px;
            text-align: center;
            cursor: pointer;
            position: relative;

            &--disabled {
                opacity: 0.5;
                cursor: default;
            }

            &--not-possible {
                cursor: default;
                background: #dc3545;
            }
        }

        .progress {
            font-size: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 0.5;
            top: 0;
            left: 0;
        }
    }

    .input-wrapper {
        align-items: center;
        position: relative;
        width: 100%;
        min-height: 56px;
        margin-bottom: 15px;

        textarea {
            resize: none;
            padding-top: 20px;
            height: 80px;
            line-height: 18px;
        }

        textarea ~ &__label {
            top: 35%;
            background: #f9f9f9;
            padding-top: 8px;
            margin-top: -8px;
            width: calc(100% - 30px);
        }

        &__clear {
            width: 32px;
            height: 32px;
            background: transparent no-repeat center url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.2725 5.81705C11.5738 5.51576 11.5738 5.02727 11.2725 4.72597C10.9712 4.42468 10.4827 4.42468 10.1814 4.72597L7.99837 6.90899L5.81721 4.72784C5.51592 4.42654 5.02742 4.42654 4.72613 4.72784C4.42483 5.02913 4.42483 5.51762 4.72613 5.81892L6.90729 8.00008L4.72597 10.1814C4.42468 10.4827 4.42468 10.9712 4.72597 11.2725C5.02727 11.5738 5.51576 11.5738 5.81705 11.2725L7.99837 9.09116L10.1815 11.2743C10.4828 11.5756 10.9713 11.5756 11.2726 11.2743C11.5739 10.973 11.5739 10.4846 11.2726 10.1833L9.08945 8.00008L11.2725 5.81705Z' fill='%23B4B4B4'/%3E%3C/svg%3E");
            position: absolute;
            top: 12px;
            right: 8px;
            -webkit-transition: all .3s;
            -o-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            opacity: 0;
            border: 0;
            cursor: pointer;
            z-index: 2;
        }

        &__label {
            display: inline-block;
            visibility: visible;
            user-select: none;
            position: absolute;
            color: rgb(131, 130, 135);
            font-size: 14px;
            line-height: 16px;
            pointer-events: none;
            transition: top 0.2s ease 0s, transform 0.2s ease 0s, font-size 0.2s ease 0s;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            z-index: 1;
        }

        &__input {
            font-size: 14px;
            position: relative;
            color: rgb(25, 25, 25);
            line-height: 24px;
            letter-spacing: 0.15px;
            height: 100%;
            width: 100%;
            min-height: 56px;
            padding-left: 14px;
            padding-top: 25px;
            padding-right: 14px;
            border: 2px solid #F9F9F9;
            background: #F9F9F9;
            border-radius: 4px;
            transition: border-color 0.2s ease 0s, font-size 0.2s ease 0s, letter-spacing 0.2s ease 0s;


            &::-webkit-input-placeholder { /* WebKit browsers */
                color: #fff;
            }
            &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                color: #fff;
                opacity: 1;
            }
            &::-moz-placeholder { /* Mozilla Firefox 19+ */
                color: #fff;
                opacity: 1;
            }
            &:-ms-input-placeholder { /* Internet Explorer 10+ */
                color: #fff;
            }
        }

        &__input:focus ~ &__label,
        &__input:not(:placeholder-shown) ~ &__label {
            font-size: 12px;
            letter-spacing: 0.4px;
            top: 8px;
            transform: translateY(0px);
        }

        &__clear:hover,
        &__input:hover ~ &__clear,
        &__input:focus ~ &__clear {
            opacity: 1;
        }
    }

    .delivery {
        margin-top: 30px;

        &__title {
            font-weight: 900;
            font-size: 22px;
            margin-bottom: 15px;
        }
    }
</style>
