import * as types from '@/store/mutation-types'
import api from '@/services/api/payments'
import { buildSuccess, handleError } from '@/utils/utils'
import { store } from '@/store'

const getters = {
    profilePayments: (state) => state.profilePayments
};

const actions = {
    profilePayments({commit}, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .payments(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_PROFILE_PAYMENTS, response.data.payments);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    profilePaymentsAdd({commit, dispatch }, payload) {
        commit(types.SHOW_SCREEN_LOADING, true);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .paymentsAdd(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        dispatch("cartGetRequest");
                        dispatch("profilePayments");
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    dispatch("cartGetRequest");
                    dispatch("profilePayments");
                })
        })
    },
    profilePaymentsDelete({commit, dispatch }, payload) {
        commit(types.SHOW_SCREEN_LOADING, true);
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .paymentsDelete(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        dispatch("cartGetRequest");
                        dispatch("profilePayments");
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    dispatch("cartGetRequest");
                    dispatch("profilePayments");
                })
        })
    },
    profilePaymentsDefault({commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .paymentsDefault(payload)
                .then((response) => {
                    if (response.status === 200) {
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        );
                        dispatch("cartGetRequest");
                        dispatch("profilePayments");
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);
                    dispatch("cartGetRequest");
                    dispatch("profilePayments");
                })
        })
    },
};

const mutations = {
    [types.FILL_PROFILE_PAYMENTS](state, data) {
        state.profilePayments = data
    }
};

const state = {
    profilePayments: [],
};

export default {
    state,
    getters,
    actions,
    mutations
}
