<template>
    <div class="product-grid">
        <h2 class="product-grid__title">{{ layout.title }}</h2>
        <div class="product-grid__products">
            <div v-for="product in layout.products"
                 class="product-grid__item">

                <SearchProduct
                        :product="product"/>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchProduct from '@/components/search/SearchProduct.vue'

    export default {
        name: 'SearchLayoutProductList',
        components: {
            SearchProduct
        },
        props: ['layout']
    }
</script>

<style lang="scss" scoped>
    .product-grid {
        padding-top: 10px;

        &__title {
            font-size: 24px;
            margin: 0 15px 0;
        }

        &__products {
            height: 100%;
        }

        &__item {
            margin: 0 15px;
            border-bottom: 1px solid #eee;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
</style>