import axios from 'axios'
import qs from 'qs';

export default {
    cart() {
        return axios.get('v1/cart')
    },
    cartClear() {
        return axios.get('v1/cart/clear')
    },
    cartSlots() {
        return axios.get('v1/cart/slots')
    },
    cartUpdate(payload) {
        return axios.post('v1/cart', qs.stringify(payload))
    },
    cartProduct(payload) {
        return axios.post('v1/cart/product', qs.stringify(payload))
    },
    cartWantMore(payload) {
        return axios.post('v1/cart/wantMore', qs.stringify(payload))
    },
    cartCheckout(payload) {
        return axios.post('v1/cart/checkout', qs.stringify(payload))
    },
}
