import * as types from '@/store/mutation-types'

const getters = {
    showLoading: (state) => state.showLoading,
    showScreenLoading: (state) => state.showScreenLoading,
}

const mutations = {
    [types.SHOW_LOADING](state, value) {
        state.showLoading = value
    },
    [types.SHOW_SCREEN_LOADING](state, value) {
        state.showScreenLoading = value
    }
}

const state = {
    showLoading: false,
    showScreenLoading: false
}

export default {
    state,
    getters,
    mutations
}
