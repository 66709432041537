import * as types from '@/store/mutation-types'
import api from '@/services/api/cart'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    order: (state) => state.order,
    config: (state) => state.config,
    addressSaved: (state) => state.addressSaved,
    cartChanged: (state) => state.cartChanged,
    cartMessage: (state) => state.cartMessage,
    slots: (state) => state.slots
};

const actions = {
    cartGet({ commit, dispatch }) {
        commit(types.SHOW_LOADING, true);
        commit(types.SHOW_SCREEN_LOADING, true);
        dispatch("cartGetDebounce");
    },
    cartGetDebounce: _.debounce(({ dispatch }) => {
        dispatch("cartGetRequest");
    }, 500),
    cartGetRequest({ commit }) {
        return new Promise((resolve, reject) => {
            api
                .cart()
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CART, response.data.order);
                        commit(types.FILL_CART_CONFIG, response.data.config);
                        commit(types.FILL_CART_CHANGED, response.data.order);
                        commit(types.SHOW_SCREEN_LOADING, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_SCREEN_LOADING, false);
                    handleError(error, commit, reject)
                })
        })
    },
    cartUpdate({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .cartUpdate(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CART, response.data.order);
                        commit(types.FILL_CART_ADDRESS_SAVED, response.data.order);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    cartClear({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            commit(types.SHOW_SCREEN_LOADING, true);
            api
                .cartClear()
                .then((response) => {
                    if (response.status === 200) {
                        dispatch("cartGetRequest");
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    cartSlots({ commit }) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .cartSlots()
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CART_SLOTS, response.data.slots);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject)
                })
        })
    },
    cartProductLoading({ commit }, payload) {
        commit(types.FILL_CART_CHANGED, null);
    },
    cartProduct({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .cartProduct(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CART, response.data.order);
                        commit(types.FILL_CART_CHANGED, response.data.order);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);

                    let errMsg = error && error.response && error.response.data
                        ? error.response.data.message : null;
                    if (errMsg != null && error.response.status === 400) {
                        commit(types.FILL_CART_MESSAGE, error.response.data.message);
                    }

                    dispatch("cartGetRequest");

                })
        })
    },
    cartWantMore({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING, true);
            api
                .cartWantMore(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CART_MESSAGE, response.data.message);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    handleError(error, commit, reject);

                    let errMsg = error && error.response && error.response.data
                        ? error.response.data.message : null;
                    if (errMsg != null && error.response.status === 400) {
                        commit(types.FILL_CART_MESSAGE, error.response.data.message);
                    }

                    dispatch("cartGetRequest");
                })
        })
    },
    cartMessageClear({ commit }, payload) {
        commit(types.FILL_CART_MESSAGE, null);
    }
};

const mutations = {
    [types.FILL_CART](state, data) {
        state.order = data
    },
    [types.FILL_CART_CONFIG](state, data) {
        state.config = data
    },
    [types.FILL_CART_ADDRESS_SAVED](state, data) {
        state.addressSaved = data
    },
    [types.FILL_CART_CHANGED](state, data) {
        state.cartChanged = data
    },
    [types.FILL_CART_MESSAGE](state, data) {
        state.cartMessage = data
    },
    [types.FILL_CART_SLOTS](state, data) {
        state.slots = data
    },
};

const state = {
    order: null,
    config: null,
    addressSaved: false,
    cartChanged: false,
    cartMessage: false,
    slots: null
};

export default {
    state,
    getters,
    actions,
    mutations
}
