import axios from 'axios'
import qs from 'qs';

export default {
    authGuest() {
        return axios.get('v1/auth/guest')
    },
    authSendCode(payload) {
        return axios.post('v1/auth/sendCode', qs.stringify(payload))
    },
    authCheckCode(payload) {
        return axios.post('v1/auth/checkCode', qs.stringify(payload))
    }
}
