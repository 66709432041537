import * as types from '@/store/mutation-types'
import api from '@/services/api/catalog'
import { buildSuccess, handleError } from '@/utils/utils'

const getters = {
    searchLayout: (state) => state.layout,
    isSearching: (state) => state.isSearching,
};

const actions = {
    catalogSearch({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit(types.SHOW_LOADING_CATALOG_SEARCH, true);
            api
                .catalogSearch(payload)
                .then((response) => {
                    if (response.status === 200) {
                        commit(types.FILL_CATALOG_SEARCH, response.data.layout);
                        commit(types.SHOW_LOADING_CATALOG_SEARCH, false);
                        buildSuccess(
                            null,
                            commit,
                            resolve
                        )
                    }
                })
                .catch((error) => {
                    commit(types.SHOW_LOADING_CATALOG_SEARCH, false);
                    handleError(error, commit, reject)
                })
        })
    },
    catalogSearchReset({ commit }, payload) {
        commit(types.FILL_CATALOG_SEARCH, []);
    },
};

const mutations = {
    [types.FILL_CATALOG_SEARCH](state, data) {
        state.searchLayout = data
    },
    [types.SHOW_LOADING_CATALOG_SEARCH](state, data) {
        state.isSearching = data
    },
};

const state = {
    searchLayout: [],
    isSearching: false
};

export default {
    state,
    getters,
    actions,
    mutations
}
