import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '@/store'
import Axios from "axios";
import '@/plugins/axios'
import VueMoment from "vue-moment";
import {appConfig} from "./config.js";
import BootstrapVue from 'bootstrap-vue'
import * as GmapVue from 'gmap-vue'
import VueLazyload from 'vue-lazyload'
import VueMask from 'v-mask'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/styles.scss'
import '@/assets/css/modal.scss'
import '@/assets/css/window.scss'
import '@/assets/css/loading.scss'
import '@/assets/css/slick.scss'
import '@/assets/css/toast.scss'

window.appConfig = appConfig;
Axios.defaults.baseURL = appConfig.baseAPIURL;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueMoment);
Vue.use(VueLazyload);
Vue.use(VueMask);
Vue.use(appConfig);
Vue.use(GmapVue, {
    load: {
        key: appConfig.googleMapsApiKey,
        libraries: "geometry"
    }
});

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);

new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        if (!store.getters.isGuestTokenSet) {
            // сделать запрос на гостя
            store.dispatch('authGuest')
        } else {
            // достать данные из памяти
            store.dispatch('autoLogin')
        }
    }
}).$mount('#app');
